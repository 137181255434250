import { Box, Stack, SvgIcon, Typography, useTheme } from '@mui/material'
import { ReactComponent as ArrowForwardIcon } from 'assets/basicIcons/arrowForward.svg'
import SmileTVImage from 'assets/tvImages/smile-tv.svg'
import { Button } from 'components/Button/Button'
import { TabWithCount } from 'components/Tab/TabWithCount'
import { useAppDispatch } from 'hooks/useReduxHooks'
import Image from 'mui-image'
import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { resetMosaicMask, setMosaicMask } from 'store/slices/ddw'
import { IDdwReportMeta } from 'store/types/entityTypes/ddw'

import { KeywordCard } from '../KeywordCard'
import { KeywordTable } from '../KeywordTable'
import { RecommendationsDrawer } from '../RecommendationsDrawer'
import {
  oneTimeCdsDataMap,
  oneTimeClDataMap,
  periodCdsDataMap,
  periodClDataMap,
} from './demoData'

type TTab = 'cl' | 'cds'

interface IKeywordCardListProps {
  id: string
  metaList: IDdwReportMeta[]
  isSelectedContractOneTimePoC: boolean
  selectedReportId: string
  selectedKeyword: string
  handleKeywordChange: (keyword: string) => void
}

const SCROLLABLE_CONTAINER_ID = 'keyword-table-container'

export const KeywordCardListDemo = ({
  id,
  metaList,
  isSelectedContractOneTimePoC,
  selectedReportId,
  selectedKeyword,
  handleKeywordChange,
}: IKeywordCardListProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['ddw'])
  const theme = useTheme()
  const clData = isSelectedContractOneTimePoC
    ? oneTimeClDataMap[selectedKeyword]
    : periodClDataMap[selectedKeyword]
  const cdsData = isSelectedContractOneTimePoC
    ? oneTimeCdsDataMap[selectedKeyword]
    : periodCdsDataMap[selectedKeyword]

  const selectedKeywordCount = metaList.find(
    ({ keyword }) => keyword === selectedKeyword
  )
  const [selectedTab, setSelectedTab] = useState<TTab>('cl')
  const [recommendationsDrawerOpen, setRecommendationsDrawerOpen] =
    useState(false)

  const toggleRecommendationsDrawer = () => {
    setRecommendationsDrawerOpen((open) => !open)
  }

  const handleTabChange = (tab: TTab) => {
    setSelectedTab(tab)
  }

  // 點到不同關鍵字卡片或切換不同 report 時預設 tab 在 cl
  useEffect(() => {
    setSelectedTab('cl')
  }, [selectedKeyword, selectedReportId])

  // 切換 tab 的時候 scroll 到最上方
  useEffect(() => {
    const container = document.getElementById(SCROLLABLE_CONTAINER_ID)
    if (container && container.scrollTo) {
      container.scrollTo(0, 0)
    }
  }, [selectedTab])

  useEffect(() => {
    if (isSelectedContractOneTimePoC) {
      dispatch(setMosaicMask(true))
    }

    return () => {
      dispatch(resetMosaicMask())
    }
  }, [isSelectedContractOneTimePoC])

  return (
    <Box id={id}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(5, minmax(14.75rem, 1fr))',
          gap: 2,
          mb: 2,
        }}
      >
        {metaList.map((meta) => (
          <KeywordCard
            key={meta.keyword}
            meta={meta}
            handleKeywordChange={handleKeywordChange}
            selected={selectedKeyword === meta.keyword}
            isSelectedContractOneTimePoC={isSelectedContractOneTimePoC}
          />
        ))}
      </Box>
      {!!selectedKeywordCount && (
        <Box
          sx={{
            border: `1px solid ${theme.colors.WHITE_20}`,
            borderRadius: '2px',
            bgcolor: theme.colors.BLACK_90,
            mb: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 2,
              px: 2,
              py: 1.5,
              bgcolor: theme.colors.BLACK_90,
              borderBottom: `2px solid ${theme.colors.PRIMARY}`,
            }}
          >
            <Box sx={{ display: 'flex', gap: 2 }}>
              <TabWithCount
                name={t('ddwAlert.tabs.credentialsLeak', { ns: 'ddw' })}
                handleSelect={() => handleTabChange('cl')}
                count={
                  isSelectedContractOneTimePoC &&
                  selectedKeywordCount.clCount > 100
                    ? 100
                    : selectedKeywordCount.clCount
                }
                selected={selectedTab === 'cl'}
              />
              <TabWithCount
                name={t('ddwAlert.tabs.compromisedData', { ns: 'ddw' })}
                handleSelect={() => handleTabChange('cds')}
                count={
                  isSelectedContractOneTimePoC &&
                  selectedKeywordCount.cdsCount > 100
                    ? 100
                    : selectedKeywordCount.cdsCount
                }
                selected={selectedTab === 'cds'}
              />
            </Box>
            <Button
              size="small"
              endIcon={<SvgIcon component={ArrowForwardIcon} inheritViewBox />}
              onClick={toggleRecommendationsDrawer}
            >
              {t('ddwAlert.remediation.title', { ns: 'ddw' })}
            </Button>
          </Box>
          <Box sx={{ p: 2 }}>
            {(selectedTab === 'cl' && selectedKeywordCount.clCount === 0) ||
            (selectedTab === 'cds' && selectedKeywordCount.cdsCount === 0) ? (
              <Stack sx={{ py: 12, gap: 2, alignItems: 'center' }}>
                <Typography
                  variant="titleBig"
                  sx={{
                    color: theme.colors.WHITE_60,
                    textAlign: 'center',
                  }}
                >
                  <Trans t={t} i18nKey="ddwAlert.emptyState.noNewLeaks">
                    No new leaks were detected this week.
                    <br />
                    Select the monitoring interval to view past leak records.
                  </Trans>
                </Typography>
                <Image
                  src={SmileTVImage}
                  alt="No new leaks were detected this week. Select the monitoring
                  interval to view past leak records."
                  width={328}
                  height={130}
                />
              </Stack>
            ) : (
              <KeywordTable
                selectedTab={selectedTab}
                clData={clData}
                cdsData={cdsData}
              />
            )}
          </Box>
        </Box>
      )}
      <RecommendationsDrawer
        open={recommendationsDrawerOpen}
        toggleDrawer={toggleRecommendationsDrawer}
      />
    </Box>
  )
}
