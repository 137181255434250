import { Box, Stack, Typography, useTheme } from '@mui/material'
import { PageSection } from 'components/PageSection/PageSection'
import { PAGE_TITLE_ADVERSARIES } from 'constants/pageTitle'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import {
  fetchAdversaries,
  fetchAdversariesCancelled,
  selectAdversaries,
  selectFetchAdversariesLoading,
} from 'store/slices/adversary'

import { AdversaryBlock } from './AdversaryBlock'
import { AdversaryFilter } from './AdversaryFilter'
import { AdversaryMap } from './AdversaryMap'
import { Loading } from './Loading'

export const AdversaryPage = () => {
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['adversary'])
  const adversaries = useAppSelector(selectAdversaries)
  const isAdversariesLoading = useAppSelector(selectFetchAdversariesLoading)

  const originCountryOptions = useMemo(
    () =>
      Array.from(
        new Set(
          adversaries.map((adversary) => adversary.originCountries).flat()
        )
      ).sort(),
    [adversaries]
  )
  const targetCountryOptions = useMemo(
    () =>
      Array.from(
        new Set(
          adversaries.map((adversary) => adversary.targetedCountries).flat()
        )
      ).sort(),
    [adversaries]
  )
  const targetIndustryOptions = useMemo(
    () =>
      Array.from(
        new Set(
          adversaries.map((adversary) => adversary.targetedIndustries).flat()
        )
      ).sort(),
    [adversaries]
  )

  const [searchText, setSearchText] = useState<string>('')
  const [selectedOriginCountries, setSelectedOriginCountries] =
    useState<string[]>(originCountryOptions)
  const [selectedTargetCountries, setSelectedTargetCountries] =
    useState<string[]>(targetCountryOptions)
  const [selectedTargetIndustries, setSelectedTargetIndustries] = useState<
    string[]
  >(targetIndustryOptions)

  const displayAdversaries = useMemo(() => {
    let filteredAdversaries = adversaries

    if (searchText) {
      // search text 會針對 alias, target country, target industry 做模糊搜尋
      filteredAdversaries = filteredAdversaries.filter((adversary) =>
        [
          adversary.name,
          ...adversary.aliases,
          ...adversary.targetedCountries,
          ...adversary.targetedIndustries,
        ].some((text) => text.toLowerCase().includes(searchText.toLowerCase()))
      )
    }

    if (selectedOriginCountries.length > 0) {
      filteredAdversaries = filteredAdversaries.filter((adversary) =>
        adversary.originCountries.some((origin) =>
          selectedOriginCountries.some(
            (selectOrigin) => selectOrigin === origin
          )
        )
      )
    }

    if (
      selectedTargetCountries.length > 0 &&
      selectedTargetCountries.length < targetCountryOptions.length // 全選：不過濾
    ) {
      filteredAdversaries = filteredAdversaries.filter((adversary) =>
        adversary.targetedCountries.some((targetCountry) =>
          selectedTargetCountries.some(
            (selectTargetCountry) => selectTargetCountry === targetCountry
          )
        )
      )
    }

    if (
      selectedTargetIndustries.length > 0 &&
      selectedTargetIndustries.length < targetIndustryOptions.length // 全選：不過濾
    ) {
      filteredAdversaries = filteredAdversaries.filter((adversary) =>
        adversary.targetedIndustries.some((targetIndustry) =>
          selectedTargetIndustries.some(
            (selectTargetIndustry) => selectTargetIndustry === targetIndustry
          )
        )
      )
    }

    return filteredAdversaries
  }, [
    adversaries,
    searchText,
    selectedOriginCountries,
    selectedTargetCountries,
    selectedTargetIndustries,
  ])

  const handleSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setSearchText(value)
  }

  const handleResetSearchText = () => {
    setSearchText('')
  }

  const handleOriginCountriesChange = (newOriginCountries: string[]) => {
    setSelectedOriginCountries(newOriginCountries)
  }

  const handleSelectOriginCountryByTag = (newOriginCountry: string) => {
    if (selectedOriginCountries.includes(newOriginCountry)) {
      setSelectedOriginCountries(
        selectedOriginCountries.filter(
          (country) => country !== newOriginCountry
        )
      )
    } else {
      setSelectedOriginCountries([...selectedOriginCountries, newOriginCountry])
    }
  }

  const handleTargetCountriesChange = (newTargetCountries: string[]) => {
    setSelectedTargetCountries(newTargetCountries)
  }

  const handleTargetIndustriesChange = (newTargetIndustries: string[]) => {
    setSelectedTargetIndustries(newTargetIndustries)
  }

  const handleFilterReset = () => {
    setSearchText('')
    setSelectedOriginCountries(originCountryOptions)
    setSelectedTargetCountries(targetCountryOptions)
    setSelectedTargetIndustries(targetIndustryOptions)
  }

  useEffect(() => {
    dispatch(fetchAdversaries())
    return () => {
      dispatch(fetchAdversariesCancelled())
    }
  }, [])

  useEffect(() => {
    handleOriginCountriesChange(originCountryOptions)
    handleTargetCountriesChange(targetCountryOptions)
    handleTargetIndustriesChange(targetIndustryOptions)
  }, [adversaries])

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE_ADVERSARIES}</title>
      </Helmet>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          maxWidth: `calc(120rem - ${theme.fixedConstants.FIXED_NAVBAR_WIDTH})`,
          p: 1,
        }}
      >
        <PageSection title={t('entryTitle', { ns: 'adversary' })}>
          <Stack sx={{ height: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                flexBasis: 0,
                flexGrow: 1,
                overflow: 'hidden',
              }}
            >
              <Stack
                sx={{
                  flexBasis: 0,
                  flexGrow: 1,
                }}
              >
                <Box
                  sx={{
                    p: 2,
                    bgcolor: theme.colors.BLACK_95,
                  }}
                >
                  <AdversaryFilter
                    searchText={searchText}
                    handleSearchTextChange={handleSearchTextChange}
                    handleResetSearchText={handleResetSearchText}
                    originCountryOptions={originCountryOptions}
                    selectedOriginCountries={selectedOriginCountries}
                    handleOriginCountriesChange={handleOriginCountriesChange}
                    targetCountryOptions={targetCountryOptions}
                    selectedTargetCountries={selectedTargetCountries}
                    handleTargetCountriesChange={handleTargetCountriesChange}
                    targetIndustryOptions={targetIndustryOptions}
                    selectedTargetIndustries={selectedTargetIndustries}
                    handleTargetIndustriesChange={handleTargetIndustriesChange}
                    handleFilterReset={handleFilterReset}
                  />
                </Box>
                <Box
                  sx={{
                    p: 1,
                    display: 'grid',
                    gridTemplateColumns: 'repeat(2, 1fr)',
                    [theme.breakpoints.up('lg')]: {
                      gridTemplateColumns: 'repeat(3, 1fr)',
                    },
                    [theme.breakpoints.up('xl')]: {
                      gridTemplateColumns: 'repeat(4, 1fr)',
                    },
                    gap: 1,
                    overflow: 'auto',
                  }}
                >
                  {isAdversariesLoading ? (
                    <Loading />
                  ) : displayAdversaries.length === 0 ? (
                    <Box sx={{ px: 4, py: 1 }}>
                      <Typography
                        variant="textSmallImportant"
                        color={theme.colors.WHITE_60}
                      >
                        No Results Match the Filter Criteria
                      </Typography>
                    </Box>
                  ) : (
                    displayAdversaries.map((adversary) => (
                      <Box key={adversary.name}>
                        <AdversaryBlock {...adversary} />
                      </Box>
                    ))
                  )}
                </Box>
              </Stack>
              <Box
                sx={{
                  flexShrink: 0,
                  width: '27.5rem',
                  height: '39rem',
                  [theme.breakpoints.up('desktop')]: {
                    width: '35rem',
                    height: '49.5rem',
                  },
                  [theme.breakpoints.up('xl')]: {
                    width: '42.5rem',
                    height: '60rem',
                  },
                }}
              >
                <AdversaryMap
                  adversaries={adversaries}
                  originCountryOptions={originCountryOptions}
                  selectedOriginCountries={selectedOriginCountries}
                  handleSelectOriginCountryByTag={
                    handleSelectOriginCountryByTag
                  }
                />
              </Box>
            </Box>
          </Stack>
        </PageSection>
      </Box>
    </>
  )
}
