import {
  Box,
  FormControlLabel,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { Checkbox } from 'components/Checkbox/Checkbox'
import { Dialog } from 'components/Dialog/Dialog'
import { useDateTime } from 'hooks/useDatetime'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { selectDownloadDdwReportsLoading } from 'store/slices/ddw'
import { pushSuccessSnackbar } from 'store/slices/snackbar'
import { IDdwReport } from 'store/types/entityTypes/ddw'

import PeriodReportsZip from './period-reports.zip'

interface IExportReportDialogProps {
  open: boolean
  handleClose: () => void
  reportList: IDdwReport[]
}

export const ExportReportDialogDemo = ({
  open,
  handleClose,
  reportList,
}: IExportReportDialogProps) => {
  const { t } = useTranslation(['ddw', 'snackbar'])
  const dispatch = useAppDispatch()
  const theme = useTheme()
  const { formatDuration } = useDateTime()
  const isDownloadDdwReportsLoading = useAppSelector(
    selectDownloadDdwReportsLoading
  )

  const [selectedExportReportIdList, setSelectedExportReportIdList] = useState<
    string[]
  >([])

  const handleDialogClose = () => {
    handleClose()
    setSelectedExportReportIdList([])
  }

  const handleExport = async () => {
    const element = document.createElement('a')
    element.href = PeriodReportsZip
    element.download = 'LiveMonitor Report.zip'
    document.body.appendChild(element) // Required for this to work in FireFox
    element.click()
    handleDialogClose()
    dispatch(
      pushSuccessSnackbar({
        text: t('csv.exportSuccess', { ns: 'snackbar' }),
      })
    )
  }

  const availableExport = reportList
    .filter(({ reportCount }) => reportCount > 0)
    .map(({ reportId }) => reportId)

  const isSelectedAll =
    selectedExportReportIdList.length > 0 &&
    selectedExportReportIdList.length === availableExport.length
  const indeterminate = !isSelectedAll && selectedExportReportIdList.length > 0

  const handleSelectAllChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked || indeterminate) {
      setSelectedExportReportIdList(availableExport)
    } else {
      setSelectedExportReportIdList([])
    }
  }

  const handleSelectExportReportIdChange = (
    event: ChangeEvent<HTMLInputElement>,
    reportId: string
  ) => {
    if (event.target.checked) {
      setSelectedExportReportIdList([...selectedExportReportIdList, reportId])
    } else {
      setSelectedExportReportIdList(
        selectedExportReportIdList.filter((selected) => selected !== reportId)
      )
    }
  }

  return (
    <Dialog
      open={open}
      handleDialogClose={handleDialogClose}
      title={t('ddwAlert.exportDialog.title', { ns: 'ddw' })}
      description={t('ddwAlert.exportDialog.description', { ns: 'ddw' })}
      content={
        <Box sx={{ border: `1px solid ${theme.colors.WHITE_20}` }}>
          <Box
            sx={{
              height: '2rem',
              display: 'flex',
              alignItems: 'center',
              px: 4,
              borderBottom: `1px solid ${theme.colors.WHITE_20}`,
            }}
          >
            <FormControlLabel
              sx={{
                '& .MuiFormControlLabel-label': {
                  color: theme.colors.WHITE,
                  ...theme.typography.body,
                },
              }}
              control={
                <Checkbox
                  checked={isSelectedAll}
                  indeterminate={indeterminate}
                  onChange={handleSelectAllChange}
                  disabled={availableExport.length === 0}
                />
              }
              label={
                <Typography
                  sx={{ color: theme.colors.WHITE, ml: 1 }}
                  variant="body"
                >
                  {t('ddwAlert.exportDialog.allOption', { ns: 'ddw' })}
                </Typography>
              }
            />
          </Box>
          <Stack sx={{ px: 4, maxHeight: '20rem', overflow: 'auto' }}>
            {reportList.map(
              ({ reportId, reportStartAt, reportEndAt, reportCount }) => (
                <Box
                  key={reportId}
                  sx={{
                    minHeight: '2rem',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <FormControlLabel
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        color: theme.colors.WHITE,
                        ...theme.typography.body,
                      },
                    }}
                    control={
                      <Checkbox
                        checked={selectedExportReportIdList.includes(reportId)}
                        onChange={(e) =>
                          handleSelectExportReportIdChange(e, reportId)
                        }
                        disabled={reportCount === 0}
                      />
                    }
                    label={
                      <Typography
                        sx={{ color: theme.colors.WHITE, ml: 1 }}
                        variant="body"
                      >
                        {formatDuration(
                          reportStartAt / 1000,
                          reportEndAt / 1000
                        )}
                        ({reportCount})
                      </Typography>
                    }
                  />
                </Box>
              )
            )}
          </Stack>
        </Box>
      }
      confirmButtonText={t('ddwAlert.exportDialog.exportCta', { ns: 'ddw' })}
      confirmButtonDisabled={selectedExportReportIdList.length === 0}
      confirmButtonLoading={isDownloadDdwReportsLoading}
      handleConfirmButtonClick={handleExport}
    />
  )
}
