import {
  Box,
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { Dialog } from 'components/Dialog/Dialog'
import { TabWithCount } from 'components/Tab/TabWithCount'
import { MFAKeyDialogTab } from 'constants/mfa'
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks'
import Image from 'mui-image'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  createAccountMFA,
  createAccountMFACancelled,
  selectAccountMFACreate,
  selectIsCreateAccountMFALoading,
} from 'store/slices/account'

import { MFASteps, Steps } from './Steps'

interface IKeyDialogProps {
  open: boolean
  handleBack: () => void
  handleClose: () => void
  handleConfirm: () => void
  isUpdate?: boolean
}

export const KeyDialog = ({
  open,
  handleBack,
  handleClose,
  handleConfirm,
  isUpdate,
}: IKeyDialogProps) => {
  const { t } = useTranslation(['mfa'])
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const [selectedTab, setSelectedTab] = useState(MFAKeyDialogTab.QR_CODE)
  const accountMFACreate = useAppSelector(selectAccountMFACreate)
  const isCreateAccountMFALoading = useAppSelector(
    selectIsCreateAccountMFALoading
  )

  useEffect(() => {
    // update 2FA 時，在 dispatch removeMFA action 後 epic 就會去抓 create MFA，因此這邊可跳過
    if (!accountMFACreate && !isUpdate && open) {
      dispatch(createAccountMFA())
    }

    return () => {
      if (!accountMFACreate && !isUpdate && open) {
        dispatch(createAccountMFACancelled())
      }
    }
  }, [open])

  const dialogTitle = isUpdate
    ? t('keyDialog.updateTitle', { ns: 'mfa' })
    : t('keyDialog.title', { ns: 'mfa' })

  return (
    <Dialog
      open={open}
      handleDialogBack={handleBack}
      handleDialogClose={handleClose}
      title={dialogTitle}
      content={
        <Stack sx={{ gap: 6 }}>
          <Steps step={MFASteps.SCAN_QRCODE} />
          <Typography variant="body" sx={{ color: theme.colors.WHITE }}>
            {t('keyDialog.description', { ns: 'mfa' })}
            <br />
            {isUpdate && t('updateCancelWarning', { ns: 'mfa' })}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              px: 2,
              py: 1.5,
              borderBottom: `2px solid ${theme.colors.PRIMARY}`,
            }}
          >
            <TabWithCount
              showCount={false}
              name={t('keyDialog.qrCodeTab', { ns: 'mfa' })}
              handleSelect={() => setSelectedTab(MFAKeyDialogTab.QR_CODE)}
              selected={selectedTab === MFAKeyDialogTab.QR_CODE}
            />
            <TabWithCount
              showCount={false}
              name={t('keyDialog.keyTab', { ns: 'mfa' })}
              handleSelect={() => setSelectedTab(MFAKeyDialogTab.KEY)}
              selected={selectedTab === MFAKeyDialogTab.KEY}
            />
          </Box>
          {isCreateAccountMFALoading || !accountMFACreate ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
              <CircularProgress size="2rem" />
            </Box>
          ) : selectedTab === MFAKeyDialogTab.QR_CODE ? (
            accountMFACreate && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Image
                  src={accountMFACreate.qrData}
                  alt="Search no match"
                  width={120}
                  height={120}
                />
              </Box>
            )
          ) : (
            <Typography variant="body">
              1.{' '}
              {t('keyDialog.enterName', {
                ns: 'mfa',
              })}
              <br />
              2.{' '}
              {t('keyDialog.enterKey', {
                ns: 'mfa',
                key: accountMFACreate?.secret,
              })}
            </Typography>
          )}
        </Stack>
      }
      confirmButtonText={t('keyDialog.confirmCta', { ns: 'mfa' })}
      handleConfirmButtonClick={handleConfirm}
    />
  )
}
