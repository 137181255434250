import {
  Box,
  CircularProgress,
  SvgIcon,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material'
import { ReactComponent as CloseIcon } from 'assets/basicIcons/close.svg'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { TMalwareType } from 'store/types/entityTypes/malware'
import type { TReportType } from 'store/types/entityTypes/report'
import type { TRiskLevel } from 'store/types/entityTypes/shared'
import { TTlp } from 'store/types/entityTypes/tlpTarget'

const sharedLabelStyles: SxProps = {
  px: 1,
  height: '1rem',
  borderRadius: 0.5,
  display: 'flex',
  alignItems: 'center',
}

export const DateLabel = ({ text }: { text: string }) => (
  <Box
    sx={(theme) => ({
      width: 'max-content',
      bgcolor: theme.colors.WHITE_20,
      color: theme.colors.WHITE,
      ...sharedLabelStyles,
    })}
  >
    <Typography variant="textSmallImportant" sx={{ lineHeight: '0.75rem' }}>
      {text}
    </Typography>
  </Box>
)

export const NewLabel = () => (
  <Box
    sx={(theme) => ({
      width: 'max-content',
      bgcolor: theme.colors.PRIMARY,
      color: theme.colors.BLACK_85,
      ...sharedLabelStyles,
    })}
  >
    <Typography variant="textSmallImportant" sx={{ lineHeight: '0.75rem' }}>
      New
    </Typography>
  </Box>
)

export const UpdateLabel = () => (
  <Box
    sx={(theme) => ({
      width: 'max-content',
      bgcolor: theme.colors.WHITE_20,
      color: theme.colors.WHITE_40,
      ...sharedLabelStyles,
    })}
  >
    <Typography variant="textSmallImportant" sx={{ lineHeight: '0.75rem' }}>
      Update
    </Typography>
  </Box>
)

interface IReportTypeInfo {
  displayText: string
  bgcolor: string
  textColor?: string
}

export const ReportTypeLabel = ({
  reportType,
}: {
  reportType: TReportType
}) => {
  const theme = useTheme()
  const { t } = useTranslation(['component'])

  const reportTypeMap: Record<TReportType, IReportTypeInfo | null> = {
    flashReport: {
      displayText: t('reportTypeLabel.flashReport', { ns: 'component' }),
      bgcolor: theme.colors.RAT,
    },
    monthlyReport: {
      displayText: t('reportTypeLabel.monthlyReport', { ns: 'component' }),
      bgcolor: theme.colors.DROPPER,
    },
    CTRReport: {
      displayText: t('reportTypeLabel.ctReport', { ns: 'component' }),
      bgcolor: theme.colors.ROOTKIT,
    },
    cyberAffairs: {
      displayText: t('reportTypeLabel.cyberAffairsReports', {
        ns: 'component',
      }),
      bgcolor: theme.colors.RANSOMWARE,
    },
    VIRReport: {
      displayText: t('reportTypeLabel.viReport', { ns: 'component' }),
      bgcolor: theme.colors.VIR_REPORT,
    },
    PMReport: null,
    customReport: {
      displayText: t('reportTypeLabel.customReport', { ns: 'component' }),
      bgcolor: theme.colors.LOADER,
      textColor: theme.colors.BLACK_90,
    },
    ddwTailoredReport: {
      displayText: t('reportTypeLabel.ddwTailoredReport', { ns: 'component' }),
      bgcolor: theme.colors.HACKING_TOOL,
    },
  }

  if (!reportTypeMap[reportType]) {
    return null
  }

  return (
    <Box
      sx={{
        width: 'max-content',
        bgcolor: reportTypeMap[reportType]?.bgcolor,
        ...sharedLabelStyles,
      }}
    >
      <Typography
        variant="textSmallImportant"
        sx={{
          color: reportTypeMap[reportType]?.textColor || theme.colors.WHITE,
          lineHeight: '1rem',
        }}
      >
        {reportTypeMap[reportType]?.displayText}
      </Typography>
    </Box>
  )
}

interface ITwoToneLabelProps {
  keyText: string
  valueText: string
  mainColor: string
  textColor: string
}

/**
 * @param mainColor border、標題、background 的顏色
 * @param textColor value 文字的顏色
 */
export const TwoToneLabel = ({
  keyText,
  valueText,
  mainColor,
  textColor,
}: ITwoToneLabelProps) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      height: '1rem',
      flexShrink: 0,
    }}
  >
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        border: `1px solid ${mainColor}`,
        borderRadius: '2px 0 0 2px',
        px: 1,
      }}
    >
      <Typography
        sx={{ color: mainColor, lineHeight: '0.75rem' }}
        variant="textSmallImportant"
      >
        {keyText}
      </Typography>
    </Box>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        bgcolor: mainColor,
        borderRadius: '0 2px 2px 0',
        px: 1,
      }}
    >
      <Typography
        sx={{ color: textColor, lineHeight: '0.75rem' }}
        variant="textSmallImportant"
      >
        {valueText}
      </Typography>
    </Box>
  </Box>
)

export const OutlineLabel = ({
  text,
  color,
}: {
  text: string
  color: string
}) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      border: `1px solid ${color}`,
      borderRadius: 0.5,
      px: 1,
    }}
  >
    <Typography sx={{ lineHeight: '1rem', color }} variant="textSmallImportant">
      {text}
    </Typography>
  </Box>
)

export const FirstSeenLabel = ({ date }: { date: string }) => {
  const theme = useTheme()
  const { t } = useTranslation(['component'])

  return (
    <TwoToneLabel
      keyText={t('firstSeen', { ns: 'component' })}
      valueText={date}
      mainColor={theme.colors.WHITE_60}
      textColor={theme.colors.BLACK_90}
    />
  )
}

// sample section
export const RiskLevelLabel = ({ level }: { level: TRiskLevel }) => {
  const theme = useTheme()
  const { t } = useTranslation(['component'])

  let mainColor = ''
  let valueText = ''

  switch (level) {
    case 'high':
      mainColor = theme.colors.TLPRed
      valueText = 'High'
      break
    case 'medium':
      mainColor = theme.colors.TLPAmber
      valueText = 'Medium'
      break
    case 'low':
      mainColor = theme.colors.TLPGreen
      valueText = 'Low'
      break
    default:
      break
  }

  return (
    <TwoToneLabel
      keyText={t('samplePreview.labels.risk', { ns: 'component' })}
      valueText={valueText}
      mainColor={mainColor}
      textColor={theme.colors.WHITE}
    />
  )
}

// sample section
export const NetworkActivityLabel = ({ isActive }: { isActive: boolean }) => {
  const theme = useTheme()
  const { t } = useTranslation(['component'])

  const valueText = isActive ? 'YES' : 'NOPE'
  const mainColor = isActive ? theme.colors.HACKING_TOOL : theme.colors.WEBSHELL

  return (
    <TwoToneLabel
      keyText={t('samplePreview.labels.networkActivity', { ns: 'component' })}
      valueText={valueText}
      mainColor={mainColor}
      textColor={theme.colors.BLACK_90}
    />
  )
}

// sample section
export const TLPLabel = ({ level }: { level: TTlp }) => {
  const { t } = useTranslation(['component'])
  const theme = useTheme()

  let mainColor = ''
  let textColor = theme.colors.WHITE
  let valueText = ''

  switch (level) {
    case 'white':
      mainColor = theme.colors.WHITE
      textColor = theme.colors.BLACK_95
      valueText = 'White'
      break
    case 'green':
      mainColor = theme.colors.TLPGreen
      valueText = 'Green'
      break
    case 'amber':
      mainColor = theme.colors.TLPAmber
      valueText = 'Amber'
      break
    case 'red':
      mainColor = theme.colors.TLPRed
      valueText = 'Red'
      break
    default:
      break
  }

  return (
    <TwoToneLabel
      keyText={t('samplePreview.labels.tlp', { ns: 'component' })}
      valueText={valueText}
      mainColor={mainColor}
      textColor={textColor}
    />
  )
}

export const SelectedCountLabel = ({ count }: { count: number }) => {
  const theme = useTheme()
  const { t } = useTranslation(['search'])

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box
        sx={{
          height: '1.5rem',
          display: 'flex',
          alignItems: 'center',
          p: 1,
          border:
            count > 0
              ? `1px solid ${theme.colors.TEXT_LINK_NORMAL}`
              : `1px solid ${theme.colors.DISABLE}`,
          borderRadius: '2px 0 0 2px',
        }}
      >
        <Typography
          variant="textSmallImportant"
          sx={{
            color:
              count > 0 ? theme.colors.TEXT_LINK_NORMAL : theme.colors.DISABLE,
            lineHeight: '0.75rem',
          }}
        >
          {t('downloadList.selectTab', { ns: 'search' })}
        </Typography>
      </Box>
      <Box
        sx={{
          height: '1.5rem',
          display: 'flex',
          alignItems: 'center',
          p: 1,
          bgcolor:
            count > 0 ? theme.colors.TEXT_LINK_NORMAL : theme.colors.DISABLE,
          borderRadius: '0 2px 2px 0',
        }}
      >
        <Typography
          variant="textSmallImportant"
          sx={{
            color: count > 0 ? theme.colors.BLACK_90 : theme.colors.BLACK_85,
            lineHeight: '0.75rem',
          }}
        >
          {count}
        </Typography>
      </Box>
    </Box>
  )
}

export const HighlightLabel = ({
  text,
  color,
}: {
  text: string
  color?: string
}) => (
  <Box
    sx={(theme) => ({
      width: 'fit-content',
      borderWidth: '1px 1px 1px 4px',
      borderStyle: 'solid',
      flexShrink: 0,
      borderColor: color || theme.colors.WHITE_20,
      color: color || theme.colors.WHITE,
      px: 1,
      height: '1rem',
      borderRadius: 0.5,
      display: 'flex',
      alignItems: 'center',
    })}
  >
    <Typography sx={{ lineHeight: '1rem' }} variant="textSmallImportant">
      {text}
    </Typography>
  </Box>
)

export const MalwareTypeLabel = ({ type }: { type: TMalwareType }) => {
  const theme = useTheme()

  const colorMap: Record<TMalwareType, string> = {
    RAT: theme.colors.RAT,
    Dropper: theme.colors.DROPPER,
    Loader: theme.colors.LOADER,
    Downloader: theme.colors.DOWNLOADER,
    'Hacking Tool': theme.colors.HACKING_TOOL,
    Webshell: theme.colors.WEBSHELL,
    Ransomware: theme.colors.RANSOMWARE,
    Rootkit: theme.colors.ROOTKIT,
    Wiper: theme.colors.WIPER,
  }

  return <HighlightLabel text={type} color={colorMap[type]} />
}
interface IRemovableLabelProps {
  children: NonNullable<ReactNode>
  size?: 'medium' | 'small'
  variant?: 'fill' | 'outlined'
  handleRemove?: () => void
  removable?: boolean
  color?: string
}

export const RemovableLabel = ({
  children,
  size = 'medium',
  variant = 'fill',
  handleRemove,
  removable = true,
  color,
}: IRemovableLabelProps) => {
  const theme = useTheme()

  const sizeMap = {
    small: {
      padding: 1,
      gap: 1,
      height: '1rem',
      iconWidth: '0.75rem',
    },
    medium: {
      padding: 2,
      gap: 2,
      height: '1.5rem',
      iconWidth: '1rem',
    },
  }

  const variantMap = {
    fill: {
      borderColor: 'transparent',
      backgroundColor: color || theme.colors.WHITE_20,
    },
    outlined: {
      borderColor: color || theme.colors.WHITE_20,
      backgroundColor: 'transparent',
    },
  }

  return (
    <Box
      sx={{
        width: 'fit-content',
        height: size === 'medium' ? '1.5rem' : '1rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        px: sizeMap[size].padding,
        gap: sizeMap[size].gap,
        borderRadius: 0.5,
        border: `1px solid ${variantMap[variant].borderColor}`,
        bgcolor: variantMap[variant].backgroundColor,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>{children}</Box>
      {removable && (
        <SvgIcon
          sx={{
            width: sizeMap[size].iconWidth,
            height: sizeMap[size].iconWidth,
            cursor: 'pointer',
          }}
          component={CloseIcon}
          inheritViewBox
          onClick={handleRemove}
        />
      )}
    </Box>
  )
}

interface ISampleUploadLabelProps {
  text: string
  loading?: boolean
  handleRemove?: () => void
}

export const SampleUploadLabel = ({
  text,
  loading,
  handleRemove,
}: ISampleUploadLabelProps) => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        width: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        px: 2,
        py: 0.5,
        gap: 2,
        borderRadius: 0.5,
        bgcolor: theme.colors.PRIMARY_40,
        wordBreak: 'break-word',
        '&:hover': {
          bgcolor: theme.colors.PRIMARY_20,
        },
        '&:active': {
          bgcolor: theme.colors.PRIMARY_60,
        },
      }}
    >
      <Typography variant="body" sx={{ color: theme.colors.WHITE }}>
        {text}
      </Typography>
      {handleRemove &&
        (loading ? (
          <CircularProgress size="1rem" />
        ) : (
          <SvgIcon
            sx={{ width: '0.75rem', height: '0.75rem', cursor: 'pointer' }}
            component={CloseIcon}
            inheritViewBox
            onClick={handleRemove}
          />
        ))}
    </Box>
  )
}
