import { Box, Stack, Typography, useTheme } from '@mui/material'
import HintImage from 'assets/mfa/hint.svg'
import { Dialog } from 'components/Dialog/Dialog'
import Image from 'mui-image'
import { useTranslation } from 'react-i18next'

import { MFASteps, Steps } from './Steps'

interface IHintDialogProps {
  open: boolean
  handleClose: () => void
  handleConfirm: () => void
  isUpdate?: boolean
}

export const HintDialog = ({
  open,
  handleClose,
  handleConfirm,
  isUpdate,
}: IHintDialogProps) => {
  const { t } = useTranslation(['mfa'])
  const theme = useTheme()

  const dialogTitle = isUpdate
    ? t('hintDialog.updateTitle', { ns: 'mfa' })
    : t('hintDialog.title', { ns: 'mfa' })

  return (
    <Dialog
      open={open}
      handleDialogClose={handleClose}
      title={dialogTitle}
      content={
        <Stack sx={{ gap: 6 }}>
          <Steps step={MFASteps.DOWNLOAD_APP} />
          <Typography variant="body" sx={{ color: theme.colors.WHITE }}>
            {t('hintDialog.description', { ns: 'mfa' })}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Image src={HintImage} width={132} />
          </Box>
          {isUpdate && (
            <Typography variant="body" sx={{ color: theme.colors.WHITE }}>
              {t('updateCancelWarning', { ns: 'mfa' })}
            </Typography>
          )}
        </Stack>
      }
      confirmButtonText={t('hintDialog.confirmCta', { ns: 'mfa' })}
      handleConfirmButtonClick={handleConfirm}
    />
  )
}
