import {
  ACCOUNT_ROUTE,
  ADVERSARIES_ROUTE,
  ADVERSARY_INFO_ROUTE,
  API_DOC_AUTHENTICATION_ROUTE,
  API_DOC_BASIC_AUTH_ROUTE,
  API_DOC_INTRODUCTION_ROUTE,
  API_DOC_RETRIEVE_ACCESS_TOKEN_ROUTE,
  API_DOC_ROUTE,
  API_DOC_V2_ADVERSARY_CAPABILITIES_ROUTE,
  API_DOC_V2_ADVERSARY_LIST_ROUTE,
  API_DOC_V2_ADVERSARY_MALWARE_ROUTE,
  API_DOC_V2_ADVERSARY_REPORTS_ROUTE,
  API_DOC_V2_ADVERSARY_ROUTE,
  API_DOC_V2_ADVERSARY_SAMPLES_ROUTE,
  API_DOC_V2_ADVERSARY_SEARCH_ROUTE,
  API_DOC_V2_CAPABILITY_ADVERSARIES_ROUTE,
  API_DOC_V2_CAPABILITY_MALWARE_ROUTE,
  API_DOC_V2_CAPABILITY_MITRE_TATICS_ROUTE,
  API_DOC_V2_CAPABILITY_MITRE_TECHNIQUES_ROUTE,
  API_DOC_V2_CAPABILITY_REPORTS_ROUTE,
  API_DOC_V2_CAPABILITY_ROUTE,
  API_DOC_V2_CAPABILITY_SAMPLES_ROUTE,
  API_DOC_V2_CAPABILITY_SEARCH_ROUTE,
  API_DOC_V2_DOMAIN_ADVERSARIES_ROUTE,
  API_DOC_V2_DOMAIN_ANALYSIS_STATUS_ROUTE,
  API_DOC_V2_DOMAIN_DNS_RECORDS_ROUTE,
  API_DOC_V2_DOMAIN_GET_DOMAIN_INFORMATION_ROUTE,
  API_DOC_V2_DOMAIN_OSINT_POSTS_ROUTE,
  API_DOC_V2_DOMAIN_REPORTS_ROUTE,
  API_DOC_V2_DOMAIN_ROUTE,
  API_DOC_V2_DOMAIN_SAMPLES_ROUTE,
  API_DOC_V2_DOMAIN_SEARCH_DOMAINS_ROUTE,
  API_DOC_V2_DOMAIN_WHOIS_ROUTE,
  API_DOC_V2_INTELLIGENCE_REPORTS_ROUTE,
  API_DOC_V2_IOC_DOWNLOAD_LATEST_ROUTE,
  API_DOC_V2_IOC_DOWNLOAD_ROUTE,
  API_DOC_V2_IOC_LIST_ROUTE,
  API_DOC_V2_IOC_ROUTE,
  API_DOC_V2_IP_ADVERSARIES_ROUTE,
  API_DOC_V2_IP_ANALYSIS_STATUS_ROUTE,
  API_DOC_V2_IP_DNS_RECORDS_ROUTE,
  API_DOC_V2_IP_GET_IP_INFORMATION_ROUTE,
  API_DOC_V2_IP_OSINT_POSTS_ROUTE,
  API_DOC_V2_IP_REPORTS_ROUTE,
  API_DOC_V2_IP_ROUTE,
  API_DOC_V2_IP_SAMPLES_ROUTE,
  API_DOC_V2_IP_SEARCH_IPS_ROUTE,
  API_DOC_V2_IP_WHOIS_ROUTE,
  API_DOC_V2_LIST_REPORTS_ROUTE,
  API_DOC_V2_MALWARE_ADVERSARIES_ROUTE,
  API_DOC_V2_MALWARE_CAPABILITIES_ROUTE,
  API_DOC_V2_MALWARE_LIST_ROUTE,
  API_DOC_V2_MALWARE_REPORTS_ROUTE,
  API_DOC_V2_MALWARE_ROUTE,
  API_DOC_V2_MALWARE_SAMPLES_ROUTE,
  API_DOC_V2_MALWARE_SEARCH_ROUTE,
  API_DOC_V2_PMR_GET_PMR_ROUTE,
  API_DOC_V2_PMR_LIST_PMR_ROUTE,
  API_DOC_V2_PMR_ROUTE,
  API_DOC_V2_RAW_INTEL_ROUTE,
  API_DOC_V2_RAW_INTEL_SEARCH_ROUTE,
  API_DOC_V2_RAW_INTEL_SHOW_ROUTE,
  API_DOC_V2_ROUTE,
  API_DOC_V2_SAMPLE_ADVERSARIES_ROUTE,
  API_DOC_V2_SAMPLE_CAPABILITIES_ROUTE,
  API_DOC_V2_SAMPLE_LIST_UPLOADED_SAMPLES_ROUTE,
  API_DOC_V2_SAMPLE_MALWARE_ROUTE,
  API_DOC_V2_SAMPLE_META_ROUTE,
  API_DOC_V2_SAMPLE_PREVIEW_ROUTE,
  API_DOC_V2_SAMPLE_RELATIONSHIP_ROUTE,
  API_DOC_V2_SAMPLE_REPORTS_ROUTE,
  API_DOC_V2_SAMPLE_ROUTE,
  API_DOC_V2_SAMPLE_SANDBOX_ROUTE,
  API_DOC_V2_SAMPLE_SEARCH_SAMPLES_ROUTE,
  API_DOC_V2_SAMPLE_UPLOAD_SAMPLE_ROUTE,
  APT_INTRODUCTION_ROUTE,
  CAPABILITY_ROUTE,
  CRASH_ROUTE,
  CTR_INTRODUCTION_ROUTE,
  CYBER_AFFAIRS_INTRODUCTION_ROUTE,
  DDW_ALERT_DEMO_ROUTE,
  DDW_ALERT_ENTRY_ROUTE,
  DDW_ALERT_ROUTE,
  DDW_TAILORED_CHINESE_REPORT_SAMPLE,
  DDW_TAILORED_ENGLISH_REPORT_SAMPLE,
  DDW_TAILORED_REPORTS_ENTRY_ROUTE,
  DDW_TAILORED_REPORTS_ROUTE,
  DOCUMENTS_ROUTE,
  DOMAIN_ROUTE,
  DOWNLOAD_ROUTE,
  EULA_ENGLISH_VERSION_ROUTE,
  EULA_ROUTE,
  EULA_TRADITIONAL_CHINESE_VERSION_ROUTE,
  FLASH_INTRODUCTION_ROUTE,
  IP_ROUTE,
  MALWARE_CONTENT_ROUTE,
  MALWARES_ROUTE,
  MFA_INFORMATION_ROUTE,
  MONTHLY_INTRODUCTION_ROUTE,
  NOT_FOUND_ROUTE,
  ORIGIN_SEARCH_RESULT_ROUTE,
  PM_REPORT_DETAIL_ROUTE,
  PM_REPORT_ROUTE,
  PMR_INTRODUCTION_ROUTE,
  PREFACE_INTRODUCTION_ROUTE,
  PRIVACY_POLICY_ROUTE,
  RAW_INTEL_ROUTE,
  REDIRECT_COMMENT_NOTIFICATION_ROUTE,
  REPORT_DETAIL_ROUTE,
  REPORT_INTRODUCTION_ROUTE,
  REPORTS_ROUTE,
  RFI_LIST_ROUTE,
  RISK_MONITORING_ROUTE,
  SAMPLE_DETAILS_ROUTE,
  SAMPLE_MANAGEMENT_ROUTE,
  SEARCH_RESULT_ROUTE,
  SEARCH_ROUTE,
  TACTIC_ROUTE,
  TARGET_COUNTRY_ROUTE,
  TARGET_INDUSTRY_ROUTE,
  TARGET_REGION_ROUTE,
  TECHNIQUE_ROUTE,
  TERMS_OF_SERVICE_ROUTE,
  THREAT_LANDSCAPE_ROUTE,
  VIR_INTRODUCTION_ROUTE,
  VULNERABILITY_ROUTE,
} from 'constants/routeParams'
import { RFIList } from 'pages/AccountPage/AccountComponents/RFIList/RFIList'
import { AccountPage } from 'pages/AccountPage/AccountPage'
import { AdversaryPage } from 'pages/AdversariesPage/AdversariesPage'
import { AdversaryInfoPage } from 'pages/AdversariesPage/AdversaryInfoPage/AdversaryInfoPage'
import { APIDocumentPage } from 'pages/APIDocumentPage/APIDocumentPage'
import { APIDocumentVersionTwoAdversaryCapabilities } from 'pages/APIDocumentPage/APIVersionTwo/Adversary/APIDocumentVersionTwoAdversaryCapabilities'
import { APIDocumentVersionTwoAdversaryList } from 'pages/APIDocumentPage/APIVersionTwo/Adversary/APIDocumentVersionTwoAdversaryList'
import { APIDocumentVersionTwoAdversaryMalware } from 'pages/APIDocumentPage/APIVersionTwo/Adversary/APIDocumentVersionTwoAdversaryMalware'
import { APIDocumentVersionTwoAdversaryReports } from 'pages/APIDocumentPage/APIVersionTwo/Adversary/APIDocumentVersionTwoAdversaryReports'
import { APIDocumentVersionTwoAdversarySamples } from 'pages/APIDocumentPage/APIVersionTwo/Adversary/APIDocumentVersionTwoAdversarySamples'
import { APIDocumentVersionTwoAdversarySearch } from 'pages/APIDocumentPage/APIVersionTwo/Adversary/APIDocumentVersionTwoAdversarySearch'
import { APIDocumentVersionTwoCapabilityAdversaries } from 'pages/APIDocumentPage/APIVersionTwo/Capability/APIDocumentVersionTwoCapabilityAdversaries'
import { APIDocumentVersionTwoCapabilityMalware } from 'pages/APIDocumentPage/APIVersionTwo/Capability/APIDocumentVersionTwoCapabilityMalware'
import { APIDocumentVersionTwoCapabilityMitreTactics } from 'pages/APIDocumentPage/APIVersionTwo/Capability/APIDocumentVersionTwoCapabilityMitreTactics'
import { APIDocumentVersionTwoCapabilityMitreTechniques } from 'pages/APIDocumentPage/APIVersionTwo/Capability/APIDocumentVersionTwoCapabilityMitreTechniques'
import { APIDocumentVersionTwoCapabilityReports } from 'pages/APIDocumentPage/APIVersionTwo/Capability/APIDocumentVersionTwoCapabilityReports'
import { APIDocumentVersionTwoCapabilitySamples } from 'pages/APIDocumentPage/APIVersionTwo/Capability/APIDocumentVersionTwoCapabilitySamples'
import { APIDocumentVersionTwoCapabilitySearch } from 'pages/APIDocumentPage/APIVersionTwo/Capability/APIDocumentVersionTwoCapabilitySearch'
import { APIDocumentVersionTwoDomainAdversaries } from 'pages/APIDocumentPage/APIVersionTwo/Domain/APIDocumentVersionTwoDomainAdversaries'
import { APIDocumentVersionTwoDomainAnalysisStatus } from 'pages/APIDocumentPage/APIVersionTwo/Domain/APIDocumentVersionTwoDomainAnalysisStatus'
import { APIDocumentVersionTwoDomainDnsRecords } from 'pages/APIDocumentPage/APIVersionTwo/Domain/APIDocumentVersionTwoDomainDnsRecords'
import { APIDocumentVersionTwoDomainGetDomainInformation } from 'pages/APIDocumentPage/APIVersionTwo/Domain/APIDocumentVersionTwoDomainGetDomainInformation'
import { APIDocumentVersionTwoDomainOsintPosts } from 'pages/APIDocumentPage/APIVersionTwo/Domain/APIDocumentVersionTwoDomainOsintPosts'
import { APIDocumentVersionTwoDomainReports } from 'pages/APIDocumentPage/APIVersionTwo/Domain/APIDocumentVersionTwoDomainReports'
import { APIDocumentVersionTwoDomainSamples } from 'pages/APIDocumentPage/APIVersionTwo/Domain/APIDocumentVersionTwoDomainSamples'
import { APIDocumentVersionTwoDomainSearchDomains } from 'pages/APIDocumentPage/APIVersionTwo/Domain/APIDocumentVersionTwoDomainSearchDomains'
import { APIDocumentVersionTwoDomainWhois } from 'pages/APIDocumentPage/APIVersionTwo/Domain/APIDocumentVersionTwoDomainWhois'
import { APIDocumentVersionTwoIntelligenceReportListReports } from 'pages/APIDocumentPage/APIVersionTwo/IntelligenceReportListReports'
import { APIDocumentVersionTwoIntroduction } from 'pages/APIDocumentPage/APIVersionTwo/Introduction'
import { APIDocumentVersionTwoIocBundleDownloadIocBundles } from 'pages/APIDocumentPage/APIVersionTwo/IocBundle/IocBundleDownloadIocBundles'
import { APIDocumentVersionTwoIocBundleDownloadLatestIocBundles } from 'pages/APIDocumentPage/APIVersionTwo/IocBundle/IocBundleDownloadLatestIocBundles'
import { APIDocumentVersionTwoIocBundleListIocBundles } from 'pages/APIDocumentPage/APIVersionTwo/IocBundle/IocBundleListIocBundles'
import { APIDocumentVersionTwoIPAdversaries } from 'pages/APIDocumentPage/APIVersionTwo/IP/APIDocumentVersionTwoIPAdversaries'
import { APIDocumentVersionTwoIPAnalysisStatus } from 'pages/APIDocumentPage/APIVersionTwo/IP/APIDocumentVersionTwoIPAnalysisStatus'
import { APIDocumentVersionTwoIPDnsRecords } from 'pages/APIDocumentPage/APIVersionTwo/IP/APIDocumentVersionTwoIPDnsRecords'
import { APIDocumentVersionTwoIPGetIPInformation } from 'pages/APIDocumentPage/APIVersionTwo/IP/APIDocumentVersionTwoIPGetIPInformation'
import { APIDocumentVersionTwoIPOsintPosts } from 'pages/APIDocumentPage/APIVersionTwo/IP/APIDocumentVersionTwoIPOsintPosts'
import { APIDocumentVersionTwoIPReports } from 'pages/APIDocumentPage/APIVersionTwo/IP/APIDocumentVersionTwoIPReports'
import { APIDocumentVersionTwoIPSamples } from 'pages/APIDocumentPage/APIVersionTwo/IP/APIDocumentVersionTwoIPSamples'
import { APIDocumentVersionTwoIPSearchIPs } from 'pages/APIDocumentPage/APIVersionTwo/IP/APIDocumentVersionTwoIPSearchIPs'
import { APIDocumentVersionTwoIPWhois } from 'pages/APIDocumentPage/APIVersionTwo/IP/APIDocumentVersionTwoIPWhois'
import { APIDocumentVersionTwoMalwareAdversaries } from 'pages/APIDocumentPage/APIVersionTwo/Malware/APIDocumentVersionTwoMalwareAdversaries'
import { APIDocumentVersionTwoMalwareCapabilities } from 'pages/APIDocumentPage/APIVersionTwo/Malware/APIDocumentVersionTwoMalwareCapabilities'
import { APIDocumentVersionTwoMalwareList } from 'pages/APIDocumentPage/APIVersionTwo/Malware/APIDocumentVersionTwoMalwareList'
import { APIDocumentVersionTwoMalwareReports } from 'pages/APIDocumentPage/APIVersionTwo/Malware/APIDocumentVersionTwoMalwareReports'
import { APIDocumentVersionTwoMalwareSamples } from 'pages/APIDocumentPage/APIVersionTwo/Malware/APIDocumentVersionTwoMalwareSamples'
import { APIDocumentVersionTwoMalwareSearch } from 'pages/APIDocumentPage/APIVersionTwo/Malware/APIDocumentVersionTwoMalwareSearch'
import { APIDocumentVersionTwoPatchManagementReportGetPMR } from 'pages/APIDocumentPage/APIVersionTwo/PatchManagementReport/PatchManagementReportGetPMR'
import { APIDocumentVersionTwoPatchManagementReportListPMR } from 'pages/APIDocumentPage/APIVersionTwo/PatchManagementReport/PatchManagementReportListPMR'
import { APIDocumentVersionTwoRawIntelSearch } from 'pages/APIDocumentPage/APIVersionTwo/RawIntel/Search'
import { APIDocumentVersionTwoRawIntelShow } from 'pages/APIDocumentPage/APIVersionTwo/RawIntel/Show'
import { APIDocumentVersionTwoListUploadedSamples } from 'pages/APIDocumentPage/APIVersionTwo/Sample/APIDocumentVersionTwoListUploadedSamples'
import { APIDocumentVersionTwoSampleAdversaries } from 'pages/APIDocumentPage/APIVersionTwo/Sample/APIDocumentVersionTwoSampleAdversaries'
import { APIDocumentVersionTwoSampleCapabilities } from 'pages/APIDocumentPage/APIVersionTwo/Sample/APIDocumentVersionTwoSampleCapabilities'
import { APIDocumentVersionTwoSampleMalware } from 'pages/APIDocumentPage/APIVersionTwo/Sample/APIDocumentVersionTwoSampleMalware'
import { APIDocumentVersionTwoSampleMeta } from 'pages/APIDocumentPage/APIVersionTwo/Sample/APIDocumentVersionTwoSampleMeta'
import { APIDocumentVersionTwoSamplePreview } from 'pages/APIDocumentPage/APIVersionTwo/Sample/APIDocumentVersionTwoSamplePreview'
import { APIDocumentVersionTwoSampleRelationship } from 'pages/APIDocumentPage/APIVersionTwo/Sample/APIDocumentVersionTwoSampleRelationship'
import { APIDocumentVersionTwoSampleReports } from 'pages/APIDocumentPage/APIVersionTwo/Sample/APIDocumentVersionTwoSampleReports'
import { APIDocumentVersionTwoSampleSandbox } from 'pages/APIDocumentPage/APIVersionTwo/Sample/APIDocumentVersionTwoSampleSandbox'
import { APIDocumentVersionTwoSearchSamples } from 'pages/APIDocumentPage/APIVersionTwo/Sample/APIDocumentVersionTwoSearchSamples'
import { APIDocumentVersionTwoUploadSample } from 'pages/APIDocumentPage/APIVersionTwo/Sample/APIDocumentVersionTwoUploadSample'
import { BasicAuth } from 'pages/APIDocumentPage/Authentication/BasicAuth'
import { Introduction as AuthenticationIntroduction } from 'pages/APIDocumentPage/Authentication/Introduction'
import { RetrieveAccessToken } from 'pages/APIDocumentPage/Authentication/RetrieveAccessToken'
import { CapabilityEntryPage } from 'pages/CapabilityPage/CapabilityEntryPage'
import { TacticPage } from 'pages/CapabilityPage/TacticPage'
import { TechniquePage } from 'pages/CapabilityPage/TechniquePage'
import { DDWAlertDemoPage } from 'pages/DDWPage/DDWAlertDemo/DDWAlertDemoPage'
import { DDWAlertEntry } from 'pages/DDWPage/DDWAlertEntry'
import { DDWAlertPage } from 'pages/DDWPage/DDWAlertPage'
import { DDWTailoredChineseReportSample } from 'pages/DDWPage/DDWTailoredReportSample/DDWTailoredChineseReportSample'
import { DDWTailoredEnglishReportSample } from 'pages/DDWPage/DDWTailoredReportSample/DDWTailoredEnglishReportSample'
import { DDWTailoredReportsEntry } from 'pages/DDWPage/DDWTailoredReportsEntry'
import { DDWTailoredReportsPage } from 'pages/DDWPage/DDWTailoredReportsPage'
import { RiskMonitoringPage } from 'pages/DDWPage/RiskMonitoringPage'
import { DocumentPage } from 'pages/DocumentPage/DocumentPage'
import { DownloadPage } from 'pages/DownloadPage/DownloadPage'
import { ErrorCrashPage } from 'pages/ErrorPage/ErrorCrashPage'
import { ErrorNotFoundPage } from 'pages/ErrorPage/ErrorNotFoundPage'
import { EulaEnPage } from 'pages/EulaPage/EulaEnPage'
import { EulaZhPage } from 'pages/EulaPage/EulaZhPage'
import { DomainPage } from 'pages/IPDomainPage/DomainPage'
import { IPPage } from 'pages/IPDomainPage/IPPage'
import { LandingPage } from 'pages/LandingPage/LandingPage'
import { MalwareContentPage } from 'pages/MalwarePage/MalwareContentPage/MalwareContentPage'
import { MalwarePage } from 'pages/MalwarePage/MalwarePage'
import { MFAInformationPage } from 'pages/MFAInformationPage/MFAInformationPage'
import { PrivacyPolicyPage } from 'pages/PrivacyPolicyPage/PrivacyPolicyPage'
import { RawIntelPage } from 'pages/RawIntelPage/RawIntelPage'
import { CommentRedirectNotification } from 'pages/RedirectPage/CommentRedirectNotification'
import { CTRIntroductionPage } from 'pages/ReportIntroductionPage/APTIntroductionPage/CTRIntroductionPage'
import { FlashIntroductionPage } from 'pages/ReportIntroductionPage/APTIntroductionPage/FlashIntroductionPage'
import { MonthlyIntroductionPage } from 'pages/ReportIntroductionPage/APTIntroductionPage/MonthlyIntroductionPage'
import { CyberAffairsIntroductionPage } from 'pages/ReportIntroductionPage/CyberAffairsIntroductionPage/CyberAffairsIntroductionPage'
import { ReportIntroductionPage } from 'pages/ReportIntroductionPage/ReportIntroductionPage'
import { ReportIntroductionPrefacePage } from 'pages/ReportIntroductionPage/ReportIntroductionPrefacePage'
import { PMRIntroductionPage } from 'pages/ReportIntroductionPage/VulnerabilityIntroductionPage/PMRIntroductionPage'
import { VIRIntroductionPage } from 'pages/ReportIntroductionPage/VulnerabilityIntroductionPage/VIRIntroductionPage'
import { ReportDetailPage } from 'pages/ReportsPage/ReportDetailPage'
import { ReportListPage } from 'pages/ReportsPage/ReportListPage'
import { ReportsPage } from 'pages/ReportsPage/ReportsPage'
import { PMReportDetail } from 'pages/ReportsPage/Vulnerability/PMReportDetail'
import { SampleDetailPage } from 'pages/SampleManagementPage/SampleDetailPage/SampleDetailPage'
import { SampleManagementPage } from 'pages/SampleManagementPage/SampleManagementPage'
import { SearchPage } from 'pages/SearchPage/SearchPage'
import { OriginSearchResultPage } from 'pages/SearchPage/SearchResultPage/OriginSearchResultPage'
import { SearchResultPage } from 'pages/SearchPage/SearchResultPage/SearchResultPage'
import { TermsOfServicePage } from 'pages/TermsOfServicePage/TermsOfServicePage'
import { ThreatLandscapePage } from 'pages/ThreatLandscapePage/ThreatLandscapePage'
import { CountrySubPage } from 'pages/ThreatLandscapePage/ThreatLandscapeSubPages/CountrySubPage'
import { IndustrySubPage } from 'pages/ThreatLandscapePage/ThreatLandscapeSubPages/IndustrySubPage'
import { RegionSubPage } from 'pages/ThreatLandscapePage/ThreatLandscapeSubPages/RegionSubPage'
import { Route, Routes } from 'react-router-dom'

export const getLandingRoutes = () => (
  <Route path="/" element={<LandingPage />} />
)

export const getReportRoutes = () => (
  <Route path={`/${REPORTS_ROUTE}`}>
    <Route index element={<ReportsPage />} />
    <Route path=":reportCategory">
      <Route index element={<ReportsPage />} />
      <Route path=":reportType" element={<ReportListPage />} />
    </Route>
    <Route path={`${REPORT_DETAIL_ROUTE}`} element={<ReportDetailPage />} />
    {/* TODO: hide in May,2023 TV2.0 beta version */}
    {/* <Route
          path={`${APT_ROUTE}/${REPORT_METRICS_ROUTE}`}
          element={<ReportMetrics />}
        /> */}
    <Route
      path={`${VULNERABILITY_ROUTE}/${PM_REPORT_ROUTE}/${PM_REPORT_DETAIL_ROUTE}/`}
      element={<PMReportDetail />}
    />
  </Route>
)

export const getSearchRoutes = () => (
  <Route path={`/${SEARCH_ROUTE}`}>
    <Route index element={<SearchPage />} />
    <Route path={SEARCH_RESULT_ROUTE} element={<SearchResultPage />} />
  </Route>
)

// 舊的報告會有連結連到舊的 route，需要有個頁面來接，以便 redirect
export const getOriginSearchResultRoutes = () => (
  <Route
    path={`${ORIGIN_SEARCH_RESULT_ROUTE}`}
    element={<OriginSearchResultPage />}
  />
)

export const getThreatLandscapeRoutes = () => (
  <Route path={`/${THREAT_LANDSCAPE_ROUTE}`}>
    <Route index element={<ThreatLandscapePage />} />
    <Route
      path={`${TARGET_REGION_ROUTE}/:target`}
      element={<RegionSubPage />}
    />
    <Route
      path={`${TARGET_COUNTRY_ROUTE}/:target`}
      element={<CountrySubPage />}
    />
    <Route
      path={`${TARGET_INDUSTRY_ROUTE}/:target`}
      element={<IndustrySubPage />}
    />
  </Route>
)

export const getAdversaryRoutes = () => (
  <Route path={`/${ADVERSARIES_ROUTE}`}>
    <Route index element={<AdversaryPage />} />
    <Route path={ADVERSARY_INFO_ROUTE} element={<AdversaryInfoPage />} />
  </Route>
)

export const getMalwareRoutes = () => (
  <Route path={`/${MALWARES_ROUTE}`}>
    <Route index element={<MalwarePage />} />
    <Route path={MALWARE_CONTENT_ROUTE} element={<MalwareContentPage />} />
  </Route>
)

export const getSampleManagementRoutes = () => (
  <Route path={`/${SAMPLE_MANAGEMENT_ROUTE}`}>
    <Route index element={<SampleManagementPage />} />
  </Route>
)

export const getSampleDetailsRoutes = () => (
  <Route path={SAMPLE_DETAILS_ROUTE} element={<SampleDetailPage />} />
)

export const getCapabilityRoutes = () => (
  <Route path={`/${CAPABILITY_ROUTE}`}>
    <Route index element={<CapabilityEntryPage />} />
    <Route path={TACTIC_ROUTE} element={<TacticPage />} />
    <Route path={TECHNIQUE_ROUTE} element={<TechniquePage />} />
  </Route>
)

export const getDownloadRoutes = () => (
  <Route path={`/${DOWNLOAD_ROUTE}`} element={<DownloadPage />} />
)

export const getIPRoutes = () => (
  <Route path={`/${IP_ROUTE}`} element={<IPPage />} />
)

export const getDomainRoutes = () => (
  <Route path={`/${DOMAIN_ROUTE}`} element={<DomainPage />} />
)

export const getRawIntelRoutes = () => (
  <Route path={`/${RAW_INTEL_ROUTE}`} element={<RawIntelPage />} />
)

export const getAccountRoutes = () => (
  <Route path={`/${ACCOUNT_ROUTE}`}>
    <Route index element={<AccountPage />} />
    <Route path={`${RFI_LIST_ROUTE}`} element={<RFIList />} />
  </Route>
)

export const getReportIntroductionRoutes = () => (
  <Route
    path={`/${REPORT_INTRODUCTION_ROUTE}`}
    element={<ReportIntroductionPage />}
  >
    <Route
      path={`${PREFACE_INTRODUCTION_ROUTE}`}
      element={<ReportIntroductionPrefacePage />}
    />
    <Route
      path={`${APT_INTRODUCTION_ROUTE}/${FLASH_INTRODUCTION_ROUTE}`}
      element={<FlashIntroductionPage />}
    />
    <Route
      path={`${APT_INTRODUCTION_ROUTE}/${MONTHLY_INTRODUCTION_ROUTE}`}
      element={<MonthlyIntroductionPage />}
    />
    <Route
      path={`${APT_INTRODUCTION_ROUTE}/${CTR_INTRODUCTION_ROUTE}`}
      element={<CTRIntroductionPage />}
    />
    <Route
      path={CYBER_AFFAIRS_INTRODUCTION_ROUTE}
      element={<CyberAffairsIntroductionPage />}
    />
    <Route path={VIR_INTRODUCTION_ROUTE} element={<VIRIntroductionPage />} />
    <Route path={PMR_INTRODUCTION_ROUTE} element={<PMRIntroductionPage />} />
  </Route>
)

export const getAPIDocumentRoutes = () => (
  <Route path={`/${API_DOC_ROUTE}`} element={<APIDocumentPage />}>
    <Route
      path={`${API_DOC_AUTHENTICATION_ROUTE}/${API_DOC_INTRODUCTION_ROUTE}`}
      element={<AuthenticationIntroduction />}
    />
    <Route
      path={`${API_DOC_AUTHENTICATION_ROUTE}/${API_DOC_RETRIEVE_ACCESS_TOKEN_ROUTE}`}
      element={<RetrieveAccessToken />}
    />
    <Route
      path={`${API_DOC_AUTHENTICATION_ROUTE}/${API_DOC_BASIC_AUTH_ROUTE}`}
      element={<BasicAuth />}
    />
    <Route path={API_DOC_V2_ROUTE}>
      <Route
        path={API_DOC_INTRODUCTION_ROUTE}
        element={<APIDocumentVersionTwoIntroduction />}
      />
      <Route path={API_DOC_V2_SAMPLE_ROUTE}>
        <Route
          path={API_DOC_V2_SAMPLE_UPLOAD_SAMPLE_ROUTE}
          element={<APIDocumentVersionTwoUploadSample />}
        />
        <Route
          path={API_DOC_V2_SAMPLE_LIST_UPLOADED_SAMPLES_ROUTE}
          element={<APIDocumentVersionTwoListUploadedSamples />}
        />
        <Route
          path={API_DOC_V2_SAMPLE_SEARCH_SAMPLES_ROUTE}
          element={<APIDocumentVersionTwoSearchSamples />}
        />
        <Route
          path={API_DOC_V2_SAMPLE_META_ROUTE}
          element={<APIDocumentVersionTwoSampleMeta />}
        />
        <Route
          path={API_DOC_V2_SAMPLE_REPORTS_ROUTE}
          element={<APIDocumentVersionTwoSampleReports />}
        />
        <Route
          path={API_DOC_V2_SAMPLE_ADVERSARIES_ROUTE}
          element={<APIDocumentVersionTwoSampleAdversaries />}
        />
        <Route
          path={API_DOC_V2_SAMPLE_MALWARE_ROUTE}
          element={<APIDocumentVersionTwoSampleMalware />}
        />
        <Route
          path={API_DOC_V2_SAMPLE_CAPABILITIES_ROUTE}
          element={<APIDocumentVersionTwoSampleCapabilities />}
        />
        <Route
          path={API_DOC_V2_SAMPLE_RELATIONSHIP_ROUTE}
          element={<APIDocumentVersionTwoSampleRelationship />}
        />
        <Route
          path={API_DOC_V2_SAMPLE_SANDBOX_ROUTE}
          element={<APIDocumentVersionTwoSampleSandbox />}
        />
        <Route
          path={API_DOC_V2_SAMPLE_PREVIEW_ROUTE}
          element={<APIDocumentVersionTwoSamplePreview />}
        />
      </Route>
      <Route path={API_DOC_V2_IP_ROUTE}>
        <Route
          path={API_DOC_V2_IP_ANALYSIS_STATUS_ROUTE}
          element={<APIDocumentVersionTwoIPAnalysisStatus />}
        />
        <Route
          path={API_DOC_V2_IP_SEARCH_IPS_ROUTE}
          element={<APIDocumentVersionTwoIPSearchIPs />}
        />
        <Route
          path={API_DOC_V2_IP_GET_IP_INFORMATION_ROUTE}
          element={<APIDocumentVersionTwoIPGetIPInformation />}
        />
        <Route
          path={API_DOC_V2_IP_WHOIS_ROUTE}
          element={<APIDocumentVersionTwoIPWhois />}
        />
        <Route
          path={API_DOC_V2_IP_SAMPLES_ROUTE}
          element={<APIDocumentVersionTwoIPSamples />}
        />
        <Route
          path={API_DOC_V2_IP_REPORTS_ROUTE}
          element={<APIDocumentVersionTwoIPReports />}
        />
        <Route
          path={API_DOC_V2_IP_ADVERSARIES_ROUTE}
          element={<APIDocumentVersionTwoIPAdversaries />}
        />
        <Route
          path={API_DOC_V2_IP_DNS_RECORDS_ROUTE}
          element={<APIDocumentVersionTwoIPDnsRecords />}
        />
        <Route
          path={API_DOC_V2_IP_OSINT_POSTS_ROUTE}
          element={<APIDocumentVersionTwoIPOsintPosts />}
        />
      </Route>
      <Route path={API_DOC_V2_DOMAIN_ROUTE}>
        <Route
          path={API_DOC_V2_DOMAIN_ANALYSIS_STATUS_ROUTE}
          element={<APIDocumentVersionTwoDomainAnalysisStatus />}
        />
        <Route
          path={API_DOC_V2_DOMAIN_SEARCH_DOMAINS_ROUTE}
          element={<APIDocumentVersionTwoDomainSearchDomains />}
        />
        <Route
          path={API_DOC_V2_DOMAIN_GET_DOMAIN_INFORMATION_ROUTE}
          element={<APIDocumentVersionTwoDomainGetDomainInformation />}
        />
        <Route
          path={API_DOC_V2_DOMAIN_WHOIS_ROUTE}
          element={<APIDocumentVersionTwoDomainWhois />}
        />
        <Route
          path={API_DOC_V2_DOMAIN_SAMPLES_ROUTE}
          element={<APIDocumentVersionTwoDomainSamples />}
        />
        <Route
          path={API_DOC_V2_DOMAIN_REPORTS_ROUTE}
          element={<APIDocumentVersionTwoDomainReports />}
        />
        <Route
          path={API_DOC_V2_DOMAIN_ADVERSARIES_ROUTE}
          element={<APIDocumentVersionTwoDomainAdversaries />}
        />
        <Route
          path={API_DOC_V2_DOMAIN_DNS_RECORDS_ROUTE}
          element={<APIDocumentVersionTwoDomainDnsRecords />}
        />
        <Route
          path={API_DOC_V2_DOMAIN_OSINT_POSTS_ROUTE}
          element={<APIDocumentVersionTwoDomainOsintPosts />}
        />
      </Route>
      <Route
        path={`${API_DOC_V2_INTELLIGENCE_REPORTS_ROUTE}/${API_DOC_V2_LIST_REPORTS_ROUTE}`}
        element={<APIDocumentVersionTwoIntelligenceReportListReports />}
      />
      <Route path={API_DOC_V2_PMR_ROUTE}>
        <Route
          path={API_DOC_V2_PMR_LIST_PMR_ROUTE}
          element={<APIDocumentVersionTwoPatchManagementReportListPMR />}
        />
        <Route
          path={API_DOC_V2_PMR_GET_PMR_ROUTE}
          element={<APIDocumentVersionTwoPatchManagementReportGetPMR />}
        />
      </Route>
      <Route path={API_DOC_V2_IOC_ROUTE}>
        <Route
          path={API_DOC_V2_IOC_LIST_ROUTE}
          element={<APIDocumentVersionTwoIocBundleListIocBundles />}
        />
        <Route
          path={API_DOC_V2_IOC_DOWNLOAD_ROUTE}
          element={<APIDocumentVersionTwoIocBundleDownloadIocBundles />}
        />
        <Route
          path={API_DOC_V2_IOC_DOWNLOAD_LATEST_ROUTE}
          element={<APIDocumentVersionTwoIocBundleDownloadLatestIocBundles />}
        />
      </Route>
      <Route path={API_DOC_V2_ADVERSARY_ROUTE}>
        <Route
          path={API_DOC_V2_ADVERSARY_LIST_ROUTE}
          element={<APIDocumentVersionTwoAdversaryList />}
        />
        <Route
          path={API_DOC_V2_ADVERSARY_SEARCH_ROUTE}
          element={<APIDocumentVersionTwoAdversarySearch />}
        />
        <Route
          path={API_DOC_V2_ADVERSARY_REPORTS_ROUTE}
          element={<APIDocumentVersionTwoAdversaryReports />}
        />
        <Route
          path={API_DOC_V2_ADVERSARY_MALWARE_ROUTE}
          element={<APIDocumentVersionTwoAdversaryMalware />}
        />
        <Route
          path={API_DOC_V2_ADVERSARY_SAMPLES_ROUTE}
          element={<APIDocumentVersionTwoAdversarySamples />}
        />
        <Route
          path={API_DOC_V2_ADVERSARY_CAPABILITIES_ROUTE}
          element={<APIDocumentVersionTwoAdversaryCapabilities />}
        />
      </Route>
      <Route path={API_DOC_V2_MALWARE_ROUTE}>
        <Route
          path={API_DOC_V2_MALWARE_LIST_ROUTE}
          element={<APIDocumentVersionTwoMalwareList />}
        />
        <Route
          path={API_DOC_V2_MALWARE_SEARCH_ROUTE}
          element={<APIDocumentVersionTwoMalwareSearch />}
        />
        <Route
          path={API_DOC_V2_MALWARE_REPORTS_ROUTE}
          element={<APIDocumentVersionTwoMalwareReports />}
        />
        <Route
          path={API_DOC_V2_MALWARE_ADVERSARIES_ROUTE}
          element={<APIDocumentVersionTwoMalwareAdversaries />}
        />
        <Route
          path={API_DOC_V2_MALWARE_SAMPLES_ROUTE}
          element={<APIDocumentVersionTwoMalwareSamples />}
        />
        <Route
          path={API_DOC_V2_MALWARE_CAPABILITIES_ROUTE}
          element={<APIDocumentVersionTwoMalwareCapabilities />}
        />
      </Route>
      <Route path={API_DOC_V2_CAPABILITY_ROUTE}>
        <Route
          path={API_DOC_V2_CAPABILITY_MITRE_TATICS_ROUTE}
          element={<APIDocumentVersionTwoCapabilityMitreTactics />}
        />
        <Route
          path={API_DOC_V2_CAPABILITY_MITRE_TECHNIQUES_ROUTE}
          element={<APIDocumentVersionTwoCapabilityMitreTechniques />}
        />
        <Route
          path={API_DOC_V2_CAPABILITY_SEARCH_ROUTE}
          element={<APIDocumentVersionTwoCapabilitySearch />}
        />
        <Route
          path={API_DOC_V2_CAPABILITY_REPORTS_ROUTE}
          element={<APIDocumentVersionTwoCapabilityReports />}
        />
        <Route
          path={API_DOC_V2_CAPABILITY_ADVERSARIES_ROUTE}
          element={<APIDocumentVersionTwoCapabilityAdversaries />}
        />
        <Route
          path={API_DOC_V2_CAPABILITY_SAMPLES_ROUTE}
          element={<APIDocumentVersionTwoCapabilitySamples />}
        />
        <Route
          path={API_DOC_V2_CAPABILITY_MALWARE_ROUTE}
          element={<APIDocumentVersionTwoCapabilityMalware />}
        />
      </Route>
      <Route path={API_DOC_V2_RAW_INTEL_ROUTE}>
        <Route
          path={API_DOC_V2_RAW_INTEL_SEARCH_ROUTE}
          element={<APIDocumentVersionTwoRawIntelSearch />}
        />
        <Route
          path={API_DOC_V2_RAW_INTEL_SHOW_ROUTE}
          element={<APIDocumentVersionTwoRawIntelShow />}
        />
      </Route>
    </Route>
  </Route>
)

export const getChildrenRoutes = () => (
  <Routes>
    {getLandingRoutes()}
    {getReportRoutes()}
    {getSearchRoutes()}
    {getOriginSearchResultRoutes()}
    {getThreatLandscapeRoutes()}
    {getAdversaryRoutes()}
    {getMalwareRoutes()}
    {getSampleManagementRoutes()}
    {getSampleDetailsRoutes()}
    {getCapabilityRoutes()}
    {getDownloadRoutes()}
    {getIPRoutes()}
    {getDomainRoutes()}
    {getRawIntelRoutes()}
    {getAccountRoutes()}
    {getReportIntroductionRoutes()}
    {getAPIDocumentRoutes()}
    <Route path={`${DOCUMENTS_ROUTE}`} element={<DocumentPage />} />
    <Route
      path={`/${MFA_INFORMATION_ROUTE}`}
      element={<MFAInformationPage />}
    />
    <Route
      path={`/${EULA_ROUTE}/${EULA_ENGLISH_VERSION_ROUTE}`}
      element={<EulaEnPage />}
    />
    <Route
      path={`/${EULA_ROUTE}/${EULA_TRADITIONAL_CHINESE_VERSION_ROUTE}`}
      element={<EulaZhPage />}
    />
    <Route
      path={`/${TERMS_OF_SERVICE_ROUTE}`}
      element={<TermsOfServicePage />}
    />
    <Route path={`/${PRIVACY_POLICY_ROUTE}`} element={<PrivacyPolicyPage />} />
    <Route
      path={`/${REDIRECT_COMMENT_NOTIFICATION_ROUTE}`}
      element={<CommentRedirectNotification />}
    />
    <Route path={`/${DDW_ALERT_ROUTE}`} element={<DDWAlertPage />} />
    <Route path={`/${DDW_ALERT_ENTRY_ROUTE}`} element={<DDWAlertEntry />} />
    <Route path={`/${DDW_ALERT_DEMO_ROUTE}`} element={<DDWAlertDemoPage />} />
    <Route
      path={`/${RISK_MONITORING_ROUTE}`}
      element={<RiskMonitoringPage />}
    />
    <Route
      path={`/${DDW_TAILORED_REPORTS_ENTRY_ROUTE}`}
      element={<DDWTailoredReportsEntry />}
    />
    <Route
      path={`/${DDW_TAILORED_REPORTS_ROUTE}`}
      element={<DDWTailoredReportsPage />}
    />
    <Route
      path={`/${DDW_TAILORED_CHINESE_REPORT_SAMPLE}`}
      element={<DDWTailoredChineseReportSample />}
    />
    <Route
      path={`/${DDW_TAILORED_ENGLISH_REPORT_SAMPLE}`}
      element={<DDWTailoredEnglishReportSample />}
    />
    <Route path={`/${CRASH_ROUTE}`} element={<ErrorCrashPage />} />
    <Route path={`/${NOT_FOUND_ROUTE}`} element={<ErrorNotFoundPage />} />
    <Route path="*" element={<ErrorNotFoundPage />} />
  </Routes>
)
