import AustraliaGraphicImage from 'assets/threatLandscape/country-images/australia.png'
import IndiaGraphicImage from 'assets/threatLandscape/country-images/india.png'
import IndonesiaGraphicImage from 'assets/threatLandscape/country-images/indonesia.png'
import JapanGraphicImage from 'assets/threatLandscape/country-images/japan.png'
import MalaysiaGraphicImage from 'assets/threatLandscape/country-images/malaysia.png'
import MongoliaGraphicImage from 'assets/threatLandscape/country-images/mongolia.png'
import PhilippinesGraphicImage from 'assets/threatLandscape/country-images/philippines.png'
import SingaporeGraphicImage from 'assets/threatLandscape/country-images/singapore.png'
import SouthKoreaGraphicImage from 'assets/threatLandscape/country-images/south-korea.png'
import TaiwanGraphicImage from 'assets/threatLandscape/country-images/taiwan.png'
import ThailandGraphicImage from 'assets/threatLandscape/country-images/thailand.png'
import VietnamGraphicImage from 'assets/threatLandscape/country-images/vietnam.png'
import AustraliaBackgroundImage from 'assets/threatLandscape/item-background/country-background-australia.jpg'
import IndiaBackgroundImage from 'assets/threatLandscape/item-background/country-background-india.jpg'
import IndonesiaBackgroundImage from 'assets/threatLandscape/item-background/country-background-indonesia.jpg'
import JapanBackgroundImage from 'assets/threatLandscape/item-background/country-background-japan.jpg'
import MalaysiaBackgroundImage from 'assets/threatLandscape/item-background/country-background-malaysia.jpg'
import MongoliaBackgroundImage from 'assets/threatLandscape/item-background/country-background-mongolia.jpg'
import PhilippinesBackgroundImage from 'assets/threatLandscape/item-background/country-background-philippines.jpg'
import SingaporeBackgroundImage from 'assets/threatLandscape/item-background/country-background-singapore.jpg'
import SouthKoreaBackgroundImage from 'assets/threatLandscape/item-background/country-background-south-korea.jpg'
import TaiwanBackgroundImage from 'assets/threatLandscape/item-background/country-background-taiwan.jpg'
import ThailandBackgroundImage from 'assets/threatLandscape/item-background/country-background-thailand.jpg'
import VietnamBackgroundImage from 'assets/threatLandscape/item-background/country-background-vietnam.jpg'
import { Flag } from 'components/Flag/Flag'
import { useTranslation } from 'react-i18next'
import { ITargetCountry } from 'store/types/entityTypes/target'

export const useTargetedCountries = () => {
  const { t } = useTranslation(['threatLandscapes'])

  /**
   * @description 寫死在前端的 target countries，主要用於 threat landscape page
   */
  const targetedCountries: ITargetCountry[] = [
    {
      backgroundImageSrc: JapanBackgroundImage,
      logo: <Flag width={40} code="jp" />,
      name: 'Japan',
      displayName: t('targetCountries.japan.title', {
        ns: 'threatLandscapes',
      }),
      targetName: 'japan',
      desc: t('targetCountries.japan.description', {
        ns: 'threatLandscapes',
      }),
      graphicImageSrc: JapanGraphicImage,
    },
    {
      backgroundImageSrc: SouthKoreaBackgroundImage,
      logo: <Flag width={40} code="kr" />,
      name: 'South Korea',
      displayName: t('targetCountries.southKorea.title', {
        ns: 'threatLandscapes',
      }),
      targetName: 'south-korea',
      desc: t('targetCountries.southKorea.description', {
        ns: 'threatLandscapes',
      }),
      graphicImageSrc: SouthKoreaGraphicImage,
    },
    {
      backgroundImageSrc: TaiwanBackgroundImage,
      logo: <Flag width={40} code="tw" />,
      name: 'Taiwan',
      displayName: t('targetCountries.taiwan.title', {
        ns: 'threatLandscapes',
      }),
      targetName: 'taiwan',
      desc: t('targetCountries.taiwan.description', {
        ns: 'threatLandscapes',
      }),
      graphicImageSrc: TaiwanGraphicImage,
    },
    {
      backgroundImageSrc: VietnamBackgroundImage,
      logo: <Flag width={40} code="vn" />,
      name: 'Vietnam',
      displayName: t('targetCountries.vietnam.title', {
        ns: 'threatLandscapes',
      }),
      targetName: 'vietnam',
      desc: t('targetCountries.vietnam.description', {
        ns: 'threatLandscapes',
      }),
      graphicImageSrc: VietnamGraphicImage,
    },
    {
      backgroundImageSrc: ThailandBackgroundImage,
      logo: <Flag width={40} code="th" />,
      name: 'Thailand',
      displayName: t('targetCountries.thailand.title', {
        ns: 'threatLandscapes',
      }),
      targetName: 'thailand',
      desc: t('targetCountries.thailand.description', {
        ns: 'threatLandscapes',
      }),
      graphicImageSrc: ThailandGraphicImage,
    },
    {
      backgroundImageSrc: SingaporeBackgroundImage,
      logo: <Flag width={40} code="sg" />,
      name: 'Singapore',
      displayName: t('targetCountries.singapore.title', {
        ns: 'threatLandscapes',
      }),
      targetName: 'singapore',
      desc: t('targetCountries.singapore.description', {
        ns: 'threatLandscapes',
      }),
      graphicImageSrc: SingaporeGraphicImage,
    },
    {
      backgroundImageSrc: AustraliaBackgroundImage,
      logo: <Flag width={40} code="au" />,
      name: 'Australia',
      displayName: t('targetCountries.australia.title', {
        ns: 'threatLandscapes',
      }),
      targetName: 'australia',
      desc: t('targetCountries.australia.description', {
        ns: 'threatLandscapes',
      }),
      graphicImageSrc: AustraliaGraphicImage,
    },
    {
      backgroundImageSrc: IndiaBackgroundImage,
      logo: <Flag width={40} code="in" />,
      name: 'India',
      displayName: t('targetCountries.india.title', {
        ns: 'threatLandscapes',
      }),
      targetName: 'india',
      desc: t('targetCountries.india.description', {
        ns: 'threatLandscapes',
      }),
      graphicImageSrc: IndiaGraphicImage,
    },
    {
      backgroundImageSrc: MongoliaBackgroundImage,
      logo: <Flag width={40} code="mn" />,
      name: 'Mongolia',
      displayName: t('targetCountries.mongolia.title', {
        ns: 'threatLandscapes',
      }),
      targetName: 'mongolia',
      desc: t('targetCountries.mongolia.description', {
        ns: 'threatLandscapes',
      }),
      graphicImageSrc: MongoliaGraphicImage,
    },
    {
      backgroundImageSrc: MalaysiaBackgroundImage,
      logo: <Flag width={40} code="my" />,
      name: 'Malaysia',
      displayName: t('targetCountries.malaysia.title', {
        ns: 'threatLandscapes',
      }),
      targetName: 'malaysia',
      desc: t('targetCountries.malaysia.description', {
        ns: 'threatLandscapes',
      }),
      graphicImageSrc: MalaysiaGraphicImage,
    },
    {
      backgroundImageSrc: IndonesiaBackgroundImage,
      logo: <Flag width={40} code="id" />,
      name: 'Indonesia',
      displayName: t('targetCountries.indonesia.title', {
        ns: 'threatLandscapes',
      }),
      targetName: 'indonesia',
      desc: t('targetCountries.indonesia.description', {
        ns: 'threatLandscapes',
      }),
      graphicImageSrc: IndonesiaGraphicImage,
    },
    {
      backgroundImageSrc: PhilippinesBackgroundImage,
      logo: <Flag width={40} code="ph" />,
      name: 'Philippines',
      displayName: t('targetCountries.philippines.title', {
        ns: 'threatLandscapes',
      }),
      targetName: 'philippines',
      desc: t('targetCountries.philippines.description', {
        ns: 'threatLandscapes',
      }),
      graphicImageSrc: PhilippinesGraphicImage,
    },
  ]

  return targetedCountries
}
