import { Box, Stack, Typography, useTheme } from '@mui/material'
import { Flag } from 'components/Flag/Flag'
import { HighlightLabel } from 'components/Label/Label'
import { TextLink } from 'components/Link/Link'
import {
  ADVERSARY_INFO_ROUTE,
  SEARCH_RESULT_ROUTE,
  SEARCH_ROUTE,
} from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useImageUrl } from 'hooks/useImageSrc'
import Image from 'mui-image'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { IAdversary } from 'store/types/entityTypes/adversary'

const DISPLAY_TAG_COUNT = 3

export const AdversaryBlock = ({
  image,
  name,
  id,
  originCountries,
  aliases,
  targetedCountries,
  targetedIndustries,
}: IAdversary) => {
  const { t } = useTranslation(['component'])
  const theme = useTheme()

  const originCountry = originCountries[0] || ''

  const displayAlias = aliases.slice(0, DISPLAY_TAG_COUNT)
  const displayTargetCountries = targetedCountries.slice(0, DISPLAY_TAG_COUNT)
  const displayTargetIndustries = targetedIndustries.slice(0, DISPLAY_TAG_COUNT)

  const imageUrl = useImageUrl({ fetchImageUrl: image })

  return (
    <Stack
      sx={{
        width: '100%',
        height: '100%',
        px: 4,
        py: 2,
        gap: 2,
        alignItems: 'center',
        border: `1px solid ${theme.colors.WHITE_20}`,
        borderRadius: 0.5,
        cursor: 'pointer',
        '&:hover': {
          bgcolor: theme.colors.BLACK_60,
        },
        '&:active': {
          bgcolor: theme.colors.BLACK_95,
        },
      }}
      to={`${ADVERSARY_INFO_ROUTE}?${SearchParamKey.ADVERSARY_ID}=${id}`}
      component={Link}
    >
      {imageUrl && <Image src={imageUrl} width={128} height={128} />}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        {originCountry && <Flag width={18} country={originCountry} />}
        <Typography variant="title" sx={{ color: theme.colors.PRIMARY }}>
          {name}
        </Typography>
      </Box>
      <Box
        sx={{ display: 'flex', gap: 2, alignSelf: 'baseline', width: '100%' }}
      >
        <HighlightLabel
          text={t('adversaryPreview.labels.alias', { ns: 'component' })}
        />
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            flexWrap: 'wrap',
            flexBasis: 0,
            flexGrow: 1,
            overflow: 'hidden',
          }}
        >
          {displayAlias.length > 0 ? (
            displayAlias.map((alias, index) => {
              const isLastTag = index === displayAlias.length - 1
              const showDots = isLastTag && aliases.length > DISPLAY_TAG_COUNT
              return (
                <Typography
                  key={alias}
                  variant="textSmallImportant"
                  color={theme.colors.WHITE}
                >
                  {alias}
                  {!isLastTag && ','}
                  {showDots && '...'}
                </Typography>
              )
            })
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center', height: '1rem' }}>
              <Typography color={theme.colors.WHITE}>--</Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Stack sx={{ width: '100%', gap: 1 }}>
        <HighlightLabel
          text={t('adversaryPreview.labels.targetCountry', { ns: 'component' })}
        />
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            flexWrap: 'wrap',
            flexBasis: 0,
            flexGrow: 1,
          }}
        >
          {displayTargetCountries.length > 0 ? (
            displayTargetCountries.map((displayTargetCountry, index) => {
              const isLastTag = index === displayTargetCountries.length - 1
              const showDots =
                isLastTag && targetedCountries.length > DISPLAY_TAG_COUNT
              return (
                <TextLink
                  key={displayTargetCountry}
                  to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=${displayTargetCountry}`}
                >
                  {displayTargetCountry}
                  {!isLastTag && ','}
                  {showDots && '...'}
                </TextLink>
              )
            })
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center', height: '1rem' }}>
              <Typography color={theme.colors.WHITE}>--</Typography>
            </Box>
          )}
        </Box>
      </Stack>
      <Stack sx={{ width: '100%', gap: 1 }}>
        <HighlightLabel
          text={t('adversaryPreview.labels.targetIndustry', {
            ns: 'component',
          })}
        />
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            flexWrap: 'wrap',
            flexBasis: 0,
            flexGrow: 1,
          }}
        >
          {displayTargetIndustries.length > 0 ? (
            displayTargetIndustries.map((displayTargetIndustry, index) => {
              const isLastTag = index === displayTargetIndustries.length - 1
              const showDots =
                isLastTag && targetedIndustries.length > DISPLAY_TAG_COUNT
              return (
                <TextLink
                  key={displayTargetIndustry}
                  to={`/${SEARCH_ROUTE}/${SEARCH_RESULT_ROUTE}?${SearchParamKey.SEARCH_TEXT}=${displayTargetIndustry}`}
                >
                  {displayTargetIndustry}
                  {!isLastTag && ','}
                  {showDots && '...'}
                </TextLink>
              )
            })
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center', height: '1rem' }}>
              <Typography color={theme.colors.WHITE}>--</Typography>
            </Box>
          )}
        </Box>
      </Stack>
    </Stack>
  )
}
