import { Box, Stack } from '@mui/material'
import { CodeBlock } from 'components/CodeBlock/CodeBlock'
import { Description } from 'components/Documents/Description'
import { H1Title } from 'components/Documents/H1Title'
import { H2ColorTitle } from 'components/Documents/H2ColorTitle'
import { H2Title } from 'components/Documents/H2Title'
import { TocLink } from 'components/Documents/TocLink'
import { TocList } from 'components/Documents/TocList'
import { InlineBlock } from 'components/InlineBlock/InlineBlock'
import { ListItem, UnorderedList } from 'components/List/List'
import { DocumentContentWidth, ScrollableContainerId } from 'constants/document'
import { getPageTitleAPIDocumentByTitle } from 'constants/pageTitle'
import { Helmet } from 'react-helmet'

import { API_DOC_V2_RAW_INTEL_TITLE, API_DOC_V2_TITLE } from '../../title'

const EXAMPLE_REQUEST = `curl "https://api.threatvision.org/api/v2/raw_intels/search?query=202.182.99.224" \\
  -H "Authorization: Bearer YOUR_ACCESS_TOKEN"
`

const EXAMPLE_RESPONSE_BODY = `{
  success: true,
  raw_intels: [
    {
      id: "SkiE5uPa9",
      title: "20220727 00000680"
    }
  ]
}

# Consumed
{
  success:true,
  raw_intels:[{
    id:"SkiE5uPa9",
    title:"20220727 00000680",
    targeted_countries:["HK"],
    targeted_industries:["Unknown"],
    malwares:["TroyPlug"],
    adversaries:["Menupass"],
    mitre_techniques:["T1053.007","T1053.002"],
    vulnerabilities:["CVE-2023-21756"],
    ips:["103.159.133.205","202.182.99.224"]
  }]
}
`

const tocList = [
  { id: 'api-endpoint', text: 'API Endpoint' },
  { id: 'url-query-parameters', text: 'URL Query Parameters' },
  { id: 'response-format', text: 'Response Format' },
  { id: 'example-request', text: 'Example Request (curl)' },
  { id: 'example-response-body', text: 'Example Response Body' },
]

const TITLE = 'Search'

export const APIDocumentVersionTwoRawIntelSearch = () => (
  <>
    <Helmet>
      <title>
        {getPageTitleAPIDocumentByTitle(
          `${TITLE} - ${API_DOC_V2_RAW_INTEL_TITLE} - ${API_DOC_V2_TITLE}`
        )}
      </title>
    </Helmet>
    <Box sx={{ display: 'flex' }}>
      <Stack
        id={ScrollableContainerId.TOP_ID}
        sx={{
          width: DocumentContentWidth.WIDTH,
          maxWidth: DocumentContentWidth.MAX_WIDTH,
          minWidth: DocumentContentWidth.MIN_WIDTH,
          px: 10,
          py: 4,
          gap: 2,
          flexGrow: 1,
          flexBasis: 0,
          overflow: 'auto',
        }}
      >
        <H1Title title={API_DOC_V2_TITLE} />
        <H2ColorTitle title={API_DOC_V2_RAW_INTEL_TITLE} />
        <H2Title title={TITLE} />
        <Description>
          “TeamT5 Raw Intel” provides TeamT5’s preliminary findings on the
          malicious campaign and helps you gain a broader understanding based on
          the searched Indicators of Compromise (IOCs). However, the information
          of “TeamT5 Raw Intel” requires further investigation. We recommend
          that you contact us if you need further analysis and investigation.
        </Description>
        <Description>
          With this endpoint, you can search for related raw intels using file
          hash, IP address, or domain name.
        </Description>
        <Description>
          This endpoint does not require AAP (Automated Analysis Point). If the
          related raw intel details have been previously unlocked with AAP
          (Automated Analysis Point), the full details of the raw intel will be
          displayed. Otherwise, only <InlineBlock text="id" /> and{' '}
          <InlineBlock text="title" /> will be shown in response. Users can then
          hit the <InlineBlock text="Show" /> endpoint to access locked raw
          intel details.
        </Description>
        <TocLink text="API Endpoint" hash="api-endpoint" />
        <Description>
          GET{' '}
          <InlineBlock text="https://api.threatvision.org/api/v2/raw_intels/search" />
        </Description>
        <TocLink text="URL Query Parameters" hash="url-query-parameters" />
        <UnorderedList>
          <ListItem>
            query (optional)
            <UnorderedList>
              <ListItem>IP address, FQDN or hash to filter raw intel.</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            offset (optional)
            <UnorderedList>
              <ListItem>
                Number of results to skip. <br />
                Allows you to paginate over the results.
              </ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <TocLink text="Response Format" hash="response-format" />
        <Description>
          JSON object, with following keys:
          <UnorderedList>
            <ListItem>
              success
              <UnorderedList>
                <ListItem>The response status</ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>
              raw_intels
              <UnorderedList>
                <ListItem>id: string of raw intel id</ListItem>
                <ListItem>title: string</ListItem>
              </UnorderedList>
              <Description>
                below columns only for consumed raw intels
              </Description>
              <UnorderedList>
                <ListItem>targeted_countries: array of string(alpha2)</ListItem>
                <ListItem>targeted_industries: array of string</ListItem>
                <ListItem>malwares: array of string</ListItem>
                <ListItem>adversaries: array of string</ListItem>
                <ListItem>mitre_techniques: array of string(serials)</ListItem>
                <ListItem>vulnerabilities: array of string</ListItem>
                <ListItem>
                  hashes: array of sample hash(if query string is a hash)
                </ListItem>
                <ListItem>
                  ips: array of ip address(if query string is a ip address)
                </ListItem>
                <ListItem>
                  domains: array of domain fqdn(if query string is a fqdn)
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>
        </Description>
        <TocLink text="Example Request (curl)" hash="example-request" />
        <CodeBlock code={EXAMPLE_REQUEST} />
        <TocLink text="Example Response Body" hash="example-response-body" />
        <CodeBlock code={EXAMPLE_RESPONSE_BODY} />
        <Box id={ScrollableContainerId.BOTTOM_ID} />
      </Stack>
      <TocList tocList={tocList} />
    </Box>
  </>
)
