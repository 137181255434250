import {
  Box,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material'
import { ReactComponent as InfoIcon } from 'assets/basicIcons/info.svg'
import DDWMosaicImage from 'assets/ddw/DDW-alert-poc-mosaic.png'
import { Button } from 'components/Button/Button'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { useDateTime } from 'hooks/useDatetime'
import { useAppSelector } from 'hooks/useReduxHooks'
import Image from 'mui-image'
import { useTranslation } from 'react-i18next'
import {
  selectFetchDdwCdsLoading,
  selectFetchDdwClLoading,
  selectShowMosaicMask,
} from 'store/slices/ddw'
import { IDdwCds, IDdwCl } from 'store/types/entityTypes/ddw'
import { openMail } from 'utils/mail'

import { KeywordCdsTableLoading } from './KeywordCdsTableLoading'
import { KeywordClTableLoading } from './KeywordClTableLoading'

const SCROLLABLE_CONTAINER_ID = 'keyword-table-container'

type TTab = 'cl' | 'cds'

interface IKeywordTableProps {
  selectedTab: TTab
  clData: IDdwCl[]
  cdsData: IDdwCds[]
}

const PasswordTooltip = () => {
  const { t } = useTranslation(['ddw'])
  return (
    <Tooltip
      title={
        <Typography variant="textSmallImportant">
          {t('ddwAlert.passwordTooltip', { ns: 'ddw' })}
        </Typography>
      }
    >
      <SvgIcon
        component={InfoIcon}
        inheritViewBox
        sx={{ width: '1rem', height: '1rem' }}
      />
    </Tooltip>
  )
}

const DetectionDateTooltip = () => {
  const { t } = useTranslation(['ddw'])
  return (
    <Tooltip
      title={
        <Typography variant="textSmallImportant">
          {t('ddwAlert.detectionDateTooltip', { ns: 'ddw' })}
        </Typography>
      }
    >
      <SvgIcon
        component={InfoIcon}
        inheritViewBox
        sx={{ width: '1rem', height: '1rem' }}
      />
    </Tooltip>
  )
}

export const KeywordTable = ({
  selectedTab,
  clData,
  cdsData,
}: IKeywordTableProps) => {
  const { t } = useTranslation(['ddw'])
  const theme = useTheme()
  const { formatMonth, getDateTimeStringByTimestampsInSeconds } =
    useDateTime('GMT')
  const isFetchDdwClLoading = useAppSelector(selectFetchDdwClLoading)
  const isFetchDdwCdsLoading = useAppSelector(selectFetchDdwCdsLoading)
  const maskEnabled = useAppSelector(selectShowMosaicMask)

  const isFetchDataLoading =
    selectedTab === 'cl' ? isFetchDdwClLoading : isFetchDdwCdsLoading

  const clHeadCells = [
    { name: t('ddwAlert.credentialsLeak.headCells.domain', { ns: 'ddw' }) },
    { name: t('ddwAlert.credentialsLeak.headCells.email', { ns: 'ddw' }) },
    {
      name: t('ddwAlert.credentialsLeak.headCells.password', { ns: 'ddw' }),
      info: <PasswordTooltip />,
    },
    {
      name: t('ddwAlert.credentialsLeak.headCells.detectionDate', {
        ns: 'ddw',
      }),
      info: <DetectionDateTooltip />,
    },
    { name: t('ddwAlert.credentialsLeak.headCells.source', { ns: 'ddw' }) },
  ]

  const cdsHeadCells = [
    { name: t('ddwAlert.compromisedData.headCells.host', { ns: 'ddw' }) },
    { name: t('ddwAlert.compromisedData.headCells.user', { ns: 'ddw' }) },
    {
      name: t('ddwAlert.compromisedData.headCells.password', { ns: 'ddw' }),
      info: <PasswordTooltip />,
    },
    {
      name: t('ddwAlert.compromisedData.headCells.detectionDate', {
        ns: 'ddw',
      }),
      info: <DetectionDateTooltip />,
    },
    { name: t('ddwAlert.compromisedData.headCells.victimIp', { ns: 'ddw' }) },
    { name: t('ddwAlert.compromisedData.headCells.userName', { ns: 'ddw' }) },
  ]

  const headCells = selectedTab === 'cl' ? clHeadCells : cdsHeadCells

  const showMosaicUI =
    maskEnabled &&
    ((selectedTab === 'cl' && clData.length === 10) ||
      (selectedTab === 'cds' && cdsData.length === 10))

  return (
    <TableContainer
      id={SCROLLABLE_CONTAINER_ID}
      sx={{
        maxHeight: '24.75rem',
        border: `1px solid ${theme.colors.WHITE_20}`,
        borderRadius: 0.5,
      }}
    >
      <Table stickyHeader>
        <TableHead
          sx={{
            height: '2.5rem',
            bgcolor: theme.colors.BLACK_90,
          }}
        >
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.name}
                sx={{
                  color: theme.colors.WHITE,
                  p: 2,
                  borderBottom: `1px solid ${theme.colors.WHITE_20}`,
                  bgcolor: theme.colors.BLACK_90,
                  width: 1 / 6,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <Typography variant="titleSmall">{headCell.name}</Typography>
                  {headCell.info}
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {isFetchDataLoading ? (
          selectedTab === 'cl' ? (
            <KeywordClTableLoading />
          ) : (
            <KeywordCdsTableLoading />
          )
        ) : (
          <TableBody
            sx={{
              '& .MuiTableCell-root': {
                height: '2.25rem',
              },
            }}
          >
            {selectedTab === 'cl'
              ? clData.map((cl) => (
                  <TableRow key={cl.id}>
                    <TableCell
                      sx={{
                        color: theme.colors.WHITE,
                        p: 2,
                        borderBottom: 'none',
                      }}
                    >
                      <Typography variant="body">
                        {cl.domain || '--'}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        color: theme.colors.WHITE,
                        p: 2,
                        borderBottom: 'none',
                      }}
                    >
                      <Typography variant="body">{cl.email || '--'}</Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        color: theme.colors.WHITE,
                        p: 2,
                        borderBottom: 'none',
                      }}
                    >
                      <Typography variant="body">{cl.password}</Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        color: theme.colors.WHITE,
                        p: 2,
                        borderBottom: 'none',
                      }}
                    >
                      <Typography variant="body">
                        {cl.leakedAt ? formatMonth(cl.leakedAt / 1000) : '--'}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        color: theme.colors.WHITE,
                        p: 2,
                        borderBottom: 'none',
                      }}
                    >
                      <Typography variant="body">
                        {cl.leakedFrom || '--'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))
              : cdsData.map((cds) => (
                  <TableRow key={cds.id}>
                    <TableCell
                      sx={{
                        color: theme.colors.WHITE,
                        p: 2,
                        borderBottom: 'none',
                      }}
                    >
                      <Typography
                        variant="body"
                        sx={{ wordBreak: 'break-all' }}
                      >
                        {cds.host || '--'}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        color: theme.colors.WHITE,
                        p: 2,
                        borderBottom: 'none',
                      }}
                    >
                      <Typography variant="body">{cds.user || '--'}</Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        color: theme.colors.WHITE,
                        p: 2,
                        borderBottom: 'none',
                      }}
                    >
                      <Typography variant="body">{cds.password}</Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        color: theme.colors.WHITE,
                        p: 2,
                        borderBottom: 'none',
                      }}
                    >
                      <Typography variant="body">
                        {cds.leakedAt
                          ? getDateTimeStringByTimestampsInSeconds(
                              cds.leakedAt / 1000
                            )
                          : '--'}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        color: theme.colors.WHITE,
                        p: 2,
                        borderBottom: 'none',
                      }}
                    >
                      <Typography variant="body">{cds.ip || '--'}</Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        color: theme.colors.WHITE,
                        p: 2,
                        borderBottom: 'none',
                      }}
                    >
                      <Typography variant="body">
                        {cds.username || cds.computername || '--'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        )}
      </Table>
      {showMosaicUI && !isFetchDataLoading && (
        <Box sx={{ position: 'relative' }}>
          <Image src={DDWMosaicImage} />
          <Button
            size="large"
            color="primary"
            variant="contained"
            onClick={openMail}
            sx={{
              width: '15rem',
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            {t('contactCta', { ns: 'component' })}
          </Button>
        </Box>
      )}
    </TableContainer>
  )
}
