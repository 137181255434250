import { Box, Stack, SvgIcon, Typography, useTheme } from '@mui/material'
import { ReactComponent as DownloadIcon } from 'assets/basicIcons/download.svg'
import SmileTVImage from 'assets/tvImages/smile-tv.svg'
import { Alert } from 'components/Alert/Alert'
import { Button } from 'components/Button/Button'
import { TextLink } from 'components/Link/Link'
import { PageSection } from 'components/PageSection/PageSection'
import { GroupingHeader, Select, SelectItem } from 'components/Select/Select'
import { PAGE_TITLE_DDW_ALERT_DEMO } from 'constants/pageTitle'
import { useDateTime } from 'hooks/useDatetime'
import { useGoBack } from 'hooks/useGoBack'
import { useAppDispatch } from 'hooks/useReduxHooks'
import Image from 'mui-image'
import { ChangeEvent, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Trans, useTranslation } from 'react-i18next'
import { pushSuccessSnackbar } from 'store/slices/snackbar'
import { IDdwReportMeta } from 'store/types/entityTypes/ddw'
import { openMail } from 'utils/mail'

import {
  oneTimeReportMetaList,
  periodReportList,
  periodReportMetaList,
} from './demoData'
import { ExportReportDialogDemo } from './ExportReportDialogDemo'
import { KeywordCardListDemo } from './KeywordCardListDemo'
import OneTimeReportsZip from './one-time-reports.zip'

type TDemoContractType = 'one-time' | 'period'

export const DDWAlertDemoPage = () => {
  useGoBack()
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['ddw'])
  const theme = useTheme()
  const { formatDuration } = useDateTime()

  const [showOneTimeReminder, setShowOneTimeReminder] = useState(true)
  const [contractType, setContractType] =
    useState<TDemoContractType>('one-time')

  const isSelectedContractOneTimePoC = contractType === 'one-time'

  const reportMetaEachRowList: IDdwReportMeta[][] = []

  const reportMetaList =
    contractType === 'one-time' ? oneTimeReportMetaList : periodReportMetaList

  for (let i = 0; i < reportMetaList.length; i += 5) {
    const chunk = reportMetaList.slice(i, i + 5)
    reportMetaEachRowList.push(chunk)
  }

  const [selectedReportId, setSelectedReportId] = useState<string>('')
  const [selectedKeyword, setSelectedKeyword] = useState('')
  const [openExportDialog, setOpenExportDialog] = useState(false)

  const handleReportIdChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedReportId(event.target.value)
  }

  const handleKeywordChange = (keyword: string) => {
    setSelectedKeyword(keyword)
  }

  const handleExportDialogOpen = () => {
    setOpenExportDialog(true)
  }

  const handleExportDialogClose = () => {
    setOpenExportDialog(false)
  }

  const handleExportOneTimeReport = async () => {
    const element = document.createElement('a')
    element.href = OneTimeReportsZip
    element.download = 'Retrohunt Report.zip'
    document.body.appendChild(element) // Required for this to work in FireFox
    element.click()
    dispatch(
      pushSuccessSnackbar({
        text: t('csv.exportSuccess', { ns: 'snackbar' }),
      })
    )
  }

  const handleExportButtonClick = () => {
    if (isSelectedContractOneTimePoC) {
      handleExportOneTimeReport()
    } else {
      handleExportDialogOpen()
    }
  }

  const handleContractTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setContractType(event.target.value as TDemoContractType)
  }

  useEffect(() => {
    if (reportMetaEachRowList.length > 0) {
      const findSelectedKeywordRowIndex = reportMetaEachRowList
        .map((list) => list.find(({ keyword }) => keyword === selectedKeyword))
        .findIndex((row) => row)

      const scrollableContainer = document.getElementById(
        String(findSelectedKeywordRowIndex)
      )

      if (scrollableContainer) {
        scrollableContainer.scrollIntoView()
      }
    }
  }, [selectedKeyword])

  useEffect(() => {
    if (periodReportList.length > 0) {
      setSelectedReportId(periodReportList[0].reportId)
    }
  }, [periodReportList.length])

  useEffect(() => {
    // 切換 report 時不選 keyword
    setSelectedKeyword('')
  }, [selectedReportId])

  return (
    <>
      <Helmet>
        <title>{PAGE_TITLE_DDW_ALERT_DEMO}</title>
      </Helmet>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          maxWidth: `calc(120rem - ${theme.fixedConstants.FIXED_NAVBAR_WIDTH})`,
        }}
      >
        <Stack sx={{ p: 1, gap: 1, height: '100%' }}>
          <PageSection
            title={t('ddwAlertDemo.title', { ns: 'ddw' })}
            titleAction={
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  color: theme.colors.WHITE,
                }}
              >
                <Typography
                  variant="textSmallImportant"
                  sx={{ lineHeight: '0.75rem' }}
                >
                  {t('ddwAlert.contractPeriod', { ns: 'ddw' })}
                </Typography>
                <Box>
                  <Select
                    value={contractType}
                    onChange={handleContractTypeChange}
                    sx={{ width: '15rem' }}
                  >
                    <GroupingHeader
                      text={t('ddwAlert.contractSelector.oneTime', {
                        ns: 'ddw',
                      })}
                    />
                    <SelectItem value="one-time">2024-11-01 PoC</SelectItem>
                    <GroupingHeader
                      text={t('ddwAlert.contractSelector.weekly', {
                        ns: 'ddw',
                      })}
                    />
                    <SelectItem value="period">
                      2024-02-05~2025-02-04
                    </SelectItem>
                  </Select>
                </Box>
                <Button
                  endIcon={<SvgIcon component={DownloadIcon} inheritViewBox />}
                  onClick={handleExportButtonClick}
                >
                  {t('ddwAlert.exportCsv', { ns: 'ddw' })}
                </Button>
              </Box>
            }
            childrenOverflowStyle="hidden"
          >
            <Stack sx={{ height: '100%' }}>
              {isSelectedContractOneTimePoC ? (
                showOneTimeReminder && (
                  <Box sx={{ p: 2 }}>
                    <Alert
                      content={
                        <Box
                          sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                        >
                          <Typography variant="body" color={theme.colors.WHITE}>
                            <Trans t={t} i18nKey="ddwAlert.oneTimePoCReminder">
                              You are currently on a trial plan. The total
                              number of leaked data is shown below. If you have
                              any questions please
                              <TextLink size="medium" onClick={openMail} to="">
                                contact us
                              </TextLink>
                              .
                            </Trans>
                          </Typography>
                        </Box>
                      }
                      onClose={() => setShowOneTimeReminder(false)}
                    />
                  </Box>
                )
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    bgcolor: theme.colors.BLACK_95,
                    p: 2,
                  }}
                >
                  <Typography
                    variant="textSmallImportant"
                    sx={{ color: theme.colors.WHITE, flexShrink: 0 }}
                  >
                    {t('ddwAlert.monitoringInterval', { ns: 'ddw' })}
                  </Typography>

                  <Select
                    value={selectedReportId}
                    onChange={handleReportIdChange}
                    sx={{ width: '15rem' }}
                  >
                    {periodReportList.map((reportDurationCount) => (
                      <SelectItem
                        key={reportDurationCount.reportId}
                        value={reportDurationCount.reportId}
                      >
                        {formatDuration(
                          reportDurationCount.reportStartAt / 1000,
                          reportDurationCount.reportEndAt / 1000
                        )}
                        ({reportDurationCount.reportCount})
                      </SelectItem>
                    ))}
                  </Select>
                </Box>
              )}
              <Stack sx={{ p: 2, flexGrow: 1, overflow: 'auto' }}>
                {reportMetaEachRowList.length > 0 ? (
                  reportMetaEachRowList.map((metaList, index) => (
                    <KeywordCardListDemo
                      id={String(index)}
                      isSelectedContractOneTimePoC={
                        isSelectedContractOneTimePoC
                      }
                      key={metaList[0]?.keyword}
                      metaList={metaList}
                      selectedReportId={selectedReportId}
                      selectedKeyword={selectedKeyword}
                      handleKeywordChange={handleKeywordChange}
                    />
                  ))
                ) : (
                  <Stack sx={{ py: 12, gap: 2, alignItems: 'center' }}>
                    <Typography
                      variant="titleBig"
                      sx={{
                        color: theme.colors.WHITE_60,
                        textAlign: 'center',
                      }}
                    >
                      <Trans t={t} i18nKey="ddwAlert.emptyState.noResult">
                        You have successfully subscribed to Deep and Dark Web
                        Alert !<br /> The monitoring results will soon be
                        delivered.
                      </Trans>
                    </Typography>
                    <Image
                      src={SmileTVImage}
                      alt="You have successfully subscribed to Deep and Dark Web Alert ! The monitoring results will soon be delivered."
                      width={328}
                      height={130}
                    />
                  </Stack>
                )}
              </Stack>
            </Stack>
          </PageSection>
        </Stack>
        {!isSelectedContractOneTimePoC && (
          <ExportReportDialogDemo
            open={openExportDialog}
            handleClose={handleExportDialogClose}
            reportList={periodReportList}
          />
        )}
      </Box>
    </>
  )
}
