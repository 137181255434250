import {
  getTime,
  millisecondsToSeconds,
  set,
  subDays,
  subHours,
} from 'date-fns'
import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz'
import { useAppSelector } from 'hooks/useReduxHooks'
import { selectAccountSettings } from 'store/slices/account'

export type TRangeOption = 'all' | '24h' | '7d' | '30d' | 'custom'

interface IDateTimeRangeOption {
  dateFrom?: number | null
  dateTo?: number | null
}

interface IRangeDateParam {
  startDate: Date | null
  endDate: Date | null
}

interface IDateTimeRange {
  dateFrom: Date | null
  dateTo: Date | null
}

export const useDateTime = (specificTimezone?: string) => {
  const { tzinfo } = useAppSelector(selectAccountSettings)
  // if tzinfo = null use browser timezone
  const timeZone =
    specificTimezone ||
    tzinfo ||
    Intl.DateTimeFormat().resolvedOptions().timeZone

  const dateTime = {
    getYearAndMonthStringByTimestampsInSeconds: (timestamps: number): string =>
      formatInTimeZone(new Date(timestamps * 1000), timeZone, 'yyyy-MM'),
    getDateStringByTimestampsInSeconds: (timestamps: number): string =>
      formatInTimeZone(new Date(timestamps * 1000), timeZone, 'yyyy-MM-dd'),
    getDateTimeStringByTimestampsInSeconds: (timestamps: number): string =>
      formatInTimeZone(
        new Date(timestamps * 1000),
        timeZone,
        'yyyy-MM-dd HH:mm'
      ),
    getDateTimeWithSecondsStringByTimestampsInSeconds: (
      timestamps: number
    ): string =>
      formatInTimeZone(
        new Date(timestamps * 1000),
        timeZone,
        'yyyy-MM-dd HH:mm:ss'
      ),
    formatDuration: (startTime: number, endTime: number | null) =>
      `${formatInTimeZone(
        new Date(startTime * 1000),
        timeZone,
        'yyyy-MM-dd'
      )}~${
        endTime
          ? formatInTimeZone(new Date(endTime * 1000), timeZone, 'yyyy-MM-dd')
          : ''
      }`,
    formatExpiresDate: (timestamps: number) =>
      `~${formatInTimeZone(
        new Date(timestamps * 1000),
        timeZone,
        'yyyy-MM-dd'
      )}`,
    formatMonth: (timestamps: number) =>
      `${formatInTimeZone(new Date(timestamps * 1000), timeZone, 'yyyy-MM')}`,
    dateRangeOptionToTimes: ({
      startDate,
      endDate,
    }: IRangeDateParam): Record<TRangeOption, IDateTimeRangeOption> => ({
      'all': {},
      '24h': {
        dateFrom: millisecondsToSeconds(getTime(subHours(Date.now(), 24))),
        dateTo: millisecondsToSeconds(getTime(Date.now())),
      },
      '7d': {
        dateFrom: millisecondsToSeconds(
          getTime(
            subDays(
              zonedTimeToUtc(
                new Date(set(Date.now(), { hours: 0, minutes: 0, seconds: 0 })),
                timeZone
              ),
              7
            )
          )
        ),
        dateTo: millisecondsToSeconds(getTime(Date.now())),
      },
      '30d': {
        dateFrom: millisecondsToSeconds(
          getTime(
            subDays(
              zonedTimeToUtc(
                new Date(set(Date.now(), { hours: 0, minutes: 0, seconds: 0 })),
                timeZone
              ),
              30
            )
          )
        ),
        dateTo: millisecondsToSeconds(getTime(Date.now())),
      },
      'custom': {
        dateFrom: startDate
          ? millisecondsToSeconds(
              getTime(zonedTimeToUtc(new Date(startDate), timeZone))
            )
          : null,
        dateTo: endDate
          ? millisecondsToSeconds(
              getTime(
                zonedTimeToUtc(
                  new Date(
                    set(endDate, { hours: 23, minutes: 59, seconds: 59 })
                  ),
                  timeZone
                )
              )
            )
          : null,
      },
    }),
    rangeToDate: ({
      startDate,
      endDate,
    }: IRangeDateParam): Record<TRangeOption, IDateTimeRange> => ({
      'all': {
        dateFrom: null,
        dateTo: null,
      },
      '24h': {
        dateFrom: subHours(new Date(), 24),
        dateTo: new Date(),
      },
      '7d': {
        dateFrom: subDays(new Date(), 7),
        dateTo: new Date(),
      },
      '30d': {
        dateFrom: subDays(new Date(), 30),
        dateTo: new Date(),
      },
      'custom': {
        dateFrom: startDate,
        dateTo: endDate,
      },
    }),
  }
  return dateTime
}
