import { SxProps } from '@mui/material'
import { useTranslation } from 'react-i18next'

type TAdversaryOriginCountry =
  | 'China'
  | 'India'
  | 'North Korea'
  | 'Pakistan'
  | 'South Korea'
  | 'Vietnam'

// Map 的 key 會是後端傳來的字串，若後端有調整 adversary 的國家，此 Map 也需要同步更新
export const useAdversaryOriginCountryNameMap = () => {
  const { t } = useTranslation(['adversary'])
  const adversaryCountryNameMap: Record<
    TAdversaryOriginCountry | string,
    string
  > = {
    China: t('countries.china', { ns: 'adversary' }),
    India: t('countries.india', { ns: 'adversary' }),
    'North Korea': t('countries.northKorea', { ns: 'adversary' }),
    Pakistan: t('countries.pakistan', { ns: 'adversary' }),
    'South Korea': t('countries.southKorea', { ns: 'adversary' }),
    Vietnam: t('countries.vietnam', { ns: 'adversary' }),
  }
  return adversaryCountryNameMap
}

export const useAdversaryOriginCountryPositionMap = (): Record<
  string,
  SxProps
> => ({
  China: { left: '63%', top: '42%' },
  India: { left: '25%', top: '54%' },
  'North Korea': { left: '77%', top: '33.5%' },
  Pakistan: { left: '13%', top: '46%' },
  'South Korea': { left: '79%', top: '39%', zIndex: 1 },
  Vietnam: { left: '58%', top: '58%' },
})
