const primaryColors = {
  PRIMARY: '#F8BE34',
  PRIMARY_20: '#f8be3433',
  PRIMARY_40: '#f8be3466',
  PRIMARY_60: '#f8be3499',
  PRIMARY_80: '#f8be34cc',
}

const secondaryColors = {
  SECONDARY: '#5AA2BF',
  SECONDARY_20: '#5aa2bf33',
  SECONDARY_40: '#5aa2bf66',
  SECONDARY_60: '#5aa2bf99',
  SECONDARY_80: '#5aa2bfcc',
}

const whiteColors = {
  WHITE: '#FFFFFF',
  WHITE_10: '#FFFFFF1A',
  WHITE_20: '#ffffff33',
  WHITE_40: '#ffffff66',
  WHITE_60: '#ffffff99',
}

const blackColors = {
  BLACK_20: '#696969',
  BLACK_50: '#595959',
  BLACK_60: '#454545',
  BLACK_85: '#333333',
  BLACK_90: '#262626',
  BLACK_95: '#1A1A1A',
  BLACK_100: '#000000',
}

const TLPColors = {
  TLPRed: '#DB1919',
  TLPAmber: '#E28630',
  TLPGreen: '#20AE09',
}

const avatarColors = {
  Avatar01: '#E3A000',
  Avatar02: '#A872E6',
  Avatar03: '#F83481',
  Avatar04: '#C3EB28',
  Avatar05: '#58C2FE',
  Avatar06: '#CB34F8',
  Avatar07: '#6065D5',
  Avatar08: '#F05656',
  Avatar09: '#2ECA72',
  Avatar10: '#EC6627',
}

const errorColors = {
  ERROR: '#E53B3B',
  ERROR_40: '#e53b3b66',
  ERROR_60: '#e53b3b99',
  ERROR_80: '#e53b3bcc',
}

const colors = {
  ...primaryColors,
  ...secondaryColors,
  ...whiteColors,
  ...blackColors,
  ...TLPColors,
  ...avatarColors,
  ...errorColors,
  TEXT_LINK_NORMAL: '#FFE17D',
  HIGH_LIGHT: '#F98532',
  GOOD_80: '#109846cc',
  RAT: '#AE34F8',
  DROPPER: '#3477F8',
  LOADER: '#DDF834',
  DOWNLOADER: '#B76E02',
  HACKING_TOOL: '#44F834',
  WEBSHELL: '#A8A083',
  RANSOMWARE: '#F83434',
  ROOTKIT: '#F834C1',
  VIR_REPORT: '#6D34F7',
  WIPER: '#34F8EC',
  DISABLE: '#404040',
  MASK: '#00000040',
  ADVERSARY_BG_COLOR: '#000000BF',
  YARA: '#ABA083',
  SNORT: '#C7BFA3',
  SIGMA: '#8A8368',
}

type TColorKeys = keyof typeof colors

export type IColors = {
  [K in TColorKeys]: string
}

export default colors
