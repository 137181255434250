import { Box, Stack, Typography, useTheme } from '@mui/material'
import { Flag } from 'components/Flag/Flag'
import { useAdversaryOriginCountryNameMap } from 'hooks/useAdversaryOriginCountries'
import { useImageUrl } from 'hooks/useImageSrc'
import Image from 'mui-image'
import { useTranslation } from 'react-i18next'
import { IAdversary } from 'store/types/entityTypes/adversary'

import {
  AdversaryAliasList,
  AdversaryIntroduction,
  AdversaryTargetList,
} from './AdversaryLabels'

export const AdversaryInfo = ({
  name,
  aliases,
  overview,
  originCountries,
  targetedCountries,
  targetedIndustries,
  image,
}: IAdversary) => {
  const theme = useTheme()
  const { t } = useTranslation(['component'])
  const country = originCountries[0] || ''
  const adversaryOriginCountryNameMap = useAdversaryOriginCountryNameMap()

  const imageUrl = useImageUrl({ fetchImageUrl: image })

  return (
    <Box
      sx={{
        display: 'flex',
        pt: 2,
        px: 4,
        pb: 4,
        gap: 4,
      }}
    >
      <Stack sx={{ gap: 2, alignItems: 'center' }}>
        {imageUrl && <Image width={96} height={96} src={imageUrl} />}
        <Typography variant="title" sx={{ color: theme.colors.PRIMARY }}>
          {name}
        </Typography>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Typography
            variant="textSmallImportant"
            sx={{ color: theme.colors.WHITE }}
          >
            {adversaryOriginCountryNameMap[country] || country}
          </Typography>
          {country && <Flag width={18} country={country} />}
        </Box>
      </Stack>
      <Stack sx={{ gap: 2, color: theme.colors.WHITE }}>
        <AdversaryAliasList aliases={aliases} />
        <AdversaryTargetList
          labels={targetedCountries}
          keyText={t('adversaryPreview.labels.targetCountry', {
            ns: 'component',
          })}
        />
        <AdversaryTargetList
          labels={targetedIndustries}
          keyText={t('adversaryPreview.labels.targetIndustry', {
            ns: 'component',
          })}
        />
        <AdversaryIntroduction overview={overview} showMoreLink />
      </Stack>
    </Box>
  )
}
