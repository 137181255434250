import { Box, Stack } from '@mui/material'
import { CodeBlock } from 'components/CodeBlock/CodeBlock'
import { Description } from 'components/Documents/Description'
import { H1Title } from 'components/Documents/H1Title'
import { H2ColorTitle } from 'components/Documents/H2ColorTitle'
import { TocList } from 'components/Documents/TocList'
import { InlineBlock } from 'components/InlineBlock/InlineBlock'
import { DocumentContentWidth, ScrollableContainerId } from 'constants/document'
import { getPageTitleAPIDocumentByTitle } from 'constants/pageTitle'
import { Helmet } from 'react-helmet'

import { API_DOC_AUTH } from '../title'

const TITLE = 'Introduction'

export const Introduction = () => (
  <>
    <Helmet>
      <title>
        {getPageTitleAPIDocumentByTitle(`${TITLE} - ${API_DOC_AUTH}`)}
      </title>
    </Helmet>
    <Box sx={{ display: 'flex' }}>
      <Stack
        id={ScrollableContainerId.TOP_ID}
        sx={{
          width: DocumentContentWidth.WIDTH,
          maxWidth: DocumentContentWidth.MAX_WIDTH,
          minWidth: DocumentContentWidth.MIN_WIDTH,
          px: 10,
          py: 4,
          gap: 2,
          flexGrow: 1,
        }}
      >
        <H1Title title="Authentication" />
        <H2ColorTitle title={TITLE} />
        <Description>
          <>
            ThreatVision API follow OAuth 2.0 specification.Every API request
            are required to provide <InlineBlock text="access_token" /> in the{' '}
            <InlineBlock text="Authorization" /> header.
          </>
        </Description>
        <CodeBlock code="Authorization: Bearer YOUR_ACCESS_TOKEN" />
        <Description>
          Alternatively, HTTP Basic Authentication is also available with a
          small adjustment to OAuth 2.0.
        </Description>
        <Box id={ScrollableContainerId.BOTTOM_ID} />
      </Stack>
      <TocList />
    </Box>
  </>
)
