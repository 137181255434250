import {
  Box,
  Divider,
  SvgIcon,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material'
import { ReactComponent as CheckIcon } from 'assets/basicIcons/check.svg'
import { useTranslation } from 'react-i18next'

export enum MFASteps {
  DOWNLOAD_APP = 0,
  SCAN_QRCODE = 1,
  ENTER_KEY = 2,
}

interface IStepsProps {
  step: MFASteps
}

export const Steps = ({ step }: IStepsProps) => {
  const { t } = useTranslation(['mfa'])
  const theme = useTheme()

  const prefixStylesBase: SxProps = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    borderRadius: '50%',
    width: '1rem',
    height: '1rem',
  }
  const activePrefixStyles: SxProps = {
    ...prefixStylesBase,
    color: theme.colors.BLACK_85,
    bgcolor: theme.colors.PRIMARY,
  }

  const inActivePrefixStyles: SxProps = {
    ...prefixStylesBase,
    color: theme.colors.WHITE_40,
    bgcolor: theme.colors.BLACK_20,
  }

  const checkIcon = (
    <SvgIcon
      inheritViewBox
      component={CheckIcon}
      sx={{
        ...prefixStylesBase,
        bgcolor: theme.colors.PRIMARY,
        'path': { fill: theme.colors.BLACK_85 },
      }}
    />
  )

  const stepsMap = [
    {
      prefix:
        step > 0 ? (
          checkIcon
        ) : (
          <Typography variant="textSmallImportant" sx={activePrefixStyles}>
            1
          </Typography>
        ),
      word: (
        <Typography variant="bodySmall" color={theme.colors.WHITE}>
          {t('steps.step1', { ns: 'mfa' })}
        </Typography>
      ),
      suffixLine: (
        <Divider
          sx={{
            borderColor:
              step < 1 ? theme.colors.BLACK_20 : theme.colors.PRIMARY,
            width: '2.5rem',
          }}
        />
      ),
    },
    {
      prefix:
        step > 1 ? (
          checkIcon
        ) : (
          <Typography
            variant="textSmallImportant"
            sx={step === 1 ? activePrefixStyles : inActivePrefixStyles}
          >
            2
          </Typography>
        ),
      word: (
        <Typography
          variant="bodySmall"
          color={step < 1 ? theme.colors.BLACK_20 : theme.colors.WHITE}
        >
          {t('steps.step2', { ns: 'mfa' })}
        </Typography>
      ),
      suffixLine: (
        <Divider
          sx={{
            borderColor:
              step < 2 ? theme.colors.BLACK_20 : theme.colors.PRIMARY,
            width: '2.5rem',
          }}
        />
      ),
    },
    {
      prefix: (
        <Typography
          variant="textSmallImportant"
          sx={step === 2 ? activePrefixStyles : inActivePrefixStyles}
        >
          3
        </Typography>
      ),
      word: (
        <Typography
          variant="bodySmall"
          color={step < 2 ? theme.colors.BLACK_20 : theme.colors.WHITE}
        >
          {t('steps.step3', { ns: 'mfa' })}
        </Typography>
      ),
    },
  ]

  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      {stepsMap.map((currentStep) => (
        <Box
          key={Math.random()}
          sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
        >
          {currentStep.prefix}
          {currentStep.word}
          {currentStep.suffixLine || null}
        </Box>
      ))}
    </Box>
  )
}
