import {
  Box,
  FormControl,
  InputAdornment,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from '@mui/material'
import { ReactComponent as CloseIcon } from 'assets/basicIcons/close.svg'
import { ReactComponent as LocationIcon } from 'assets/basicIcons/location.svg'
import { ReactComponent as TargetIcon } from 'assets/basicIcons/target.svg'
import { Button } from 'components/Button/Button'
import { RemovableLabel } from 'components/Label/Label'
import { Select, SelectCheckboxItem } from 'components/Select/Select'
import { TextField } from 'components/TextField/TextField'
import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface IAdversaryFilterProps {
  searchText: string
  handleSearchTextChange: (event: ChangeEvent<HTMLInputElement>) => void
  handleResetSearchText: () => void
  selectedOriginCountries: string[]
  originCountryOptions: string[]
  handleOriginCountriesChange: (newOriginCountries: string[]) => void
  selectedTargetCountries: string[]
  targetCountryOptions: string[]
  handleTargetCountriesChange: (newTargetCountries: string[]) => void
  selectedTargetIndustries: string[]
  targetIndustryOptions: string[]
  handleTargetIndustriesChange: (newTargetIndustries: string[]) => void
  handleFilterReset: () => void
}

export const AdversaryFilter = ({
  searchText,
  handleSearchTextChange,
  handleResetSearchText,
  selectedOriginCountries,
  originCountryOptions,
  handleOriginCountriesChange,
  selectedTargetCountries,
  targetCountryOptions,
  handleTargetCountriesChange,
  selectedTargetIndustries,
  targetIndustryOptions,
  handleTargetIndustriesChange,
  handleFilterReset,
}: IAdversaryFilterProps) => {
  const { t } = useTranslation(['component'])
  const theme = useTheme()

  const isAllOriginCountriesSelected =
    originCountryOptions.length > 0 &&
    selectedOriginCountries.length === originCountryOptions.length
  const isOriginCountriesIndeterminate =
    selectedOriginCountries.length > 0 &&
    selectedOriginCountries.length < originCountryOptions.length
  const toggleSelectAllOriginCountries = () => {
    if (isAllOriginCountriesSelected) {
      handleOriginCountriesChange([])
    } else {
      handleOriginCountriesChange(originCountryOptions)
    }
  }

  const isAllTargetCountriesSelected =
    targetCountryOptions.length > 0 &&
    selectedTargetCountries.length === targetCountryOptions.length
  const isTargetCountriesIndeterminate =
    selectedTargetCountries.length > 0 &&
    selectedTargetCountries.length < targetCountryOptions.length
  const toggleSelectAllTargetCountries = () => {
    if (isAllTargetCountriesSelected) {
      handleTargetCountriesChange([])
    } else {
      handleTargetCountriesChange(targetCountryOptions)
    }
  }

  const isAllTargetIndustriesSelected =
    targetIndustryOptions.length > 0 &&
    selectedTargetIndustries.length === targetIndustryOptions.length
  const isTargetIndustriesIndeterminate =
    selectedTargetIndustries.length > 0 &&
    selectedTargetIndustries.length < targetIndustryOptions.length
  const toggleSelectAllTargetIndustries = () => {
    if (isAllTargetIndustriesSelected) {
      handleTargetIndustriesChange([])
    } else {
      handleTargetIndustriesChange(targetIndustryOptions)
    }
  }

  const [originCountriesSelectOpen, setOriginCountriesSelectOpen] =
    useState<boolean>(false)
  const [targetCountriesSelectOpen, setTargetCountriesSelectOpen] =
    useState<boolean>(false)
  const [targetIndustriesSelectOpen, setTargetIndustriesSelectOpen] =
    useState<boolean>(false)

  const handleOriginCountriesSelectOpen = () => {
    setOriginCountriesSelectOpen(true)
  }
  const handleOriginCountriesSelectClose = () => {
    setOriginCountriesSelectOpen(false)
  }

  const handleTargetCountriesSelectOpen = () => {
    setTargetCountriesSelectOpen(true)
  }
  const handleTargetCountriesSelectClose = () => {
    setTargetCountriesSelectOpen(false)
  }

  const handleTargetIndustriesSelectOpen = () => {
    setTargetIndustriesSelectOpen(true)
  }
  const handleTargetIndustriesSelectClose = () => {
    setTargetIndustriesSelectOpen(false)
  }

  const handleRemoveOriginCountryByTag = (removeOriginCountry: string) => {
    handleOriginCountriesChange(
      selectedOriginCountries.filter(
        (selectOriginCountry) => selectOriginCountry !== removeOriginCountry
      )
    )
  }

  const handleRemoveTargetCountryByTag = (removeTargetCountry: string) => {
    handleTargetCountriesChange(
      selectedTargetCountries.filter(
        (selectTargetCountry) => selectTargetCountry !== removeTargetCountry
      )
    )
  }

  const handleRemoveTargetIndustryByTag = (removeTargetIndustry: string) => {
    handleTargetIndustriesChange(
      selectedTargetIndustries.filter(
        (selectTargetIndustry) => selectTargetIndustry !== removeTargetIndustry
      )
    )
  }

  // https://stackoverflow.com/questions/53206773/material-ui-select-keep-the-scroll-at-top-if-all-items-are-selected
  // 為了讓 multiple select 打開時，能夠捲動到最上方。若無此 effect，會自動被捲到最下方（已選的最後一項）
  useEffect(() => {
    setTimeout(() => {
      document.getElementById('all')?.scrollIntoView()
    }, 0)
  }, [
    originCountriesSelectOpen,
    targetCountriesSelectOpen,
    targetIndustriesSelectOpen,
  ])

  const showTags =
    (selectedOriginCountries.length > 0 && !isAllOriginCountriesSelected) ||
    (selectedTargetCountries.length > 0 && !isAllTargetCountriesSelected) ||
    (selectedTargetIndustries.length > 0 && !isAllTargetIndustriesSelected)

  return (
    <Stack sx={{ flexGrow: 1, gap: 2 }}>
      <Box>
        <TextField
          value={searchText}
          onChange={handleSearchTextChange}
          size="medium"
          placeholder="Search Keywords"
          InputProps={
            searchText
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <SvgIcon
                        onClick={handleResetSearchText}
                        inheritViewBox
                        component={CloseIcon}
                        sx={{
                          width: '1rem',
                          height: '1rem',
                          cursor: 'pointer',
                        }}
                      />
                    </InputAdornment>
                  ),
                }
              : {}
          }
        />
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'auto 1fr',
            alignItems: 'center',
            gap: 2,
            pr: 2,
            borderRight: `1px solid ${theme.colors.WHITE_40}`,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <SvgIcon
              sx={{ width: '1rem', height: '1rem' }}
              component={LocationIcon}
              inheritViewBox
            />
            <Typography
              variant="textSmallImportant"
              sx={{ color: theme.colors.WHITE }}
            >
              Origin
            </Typography>
          </Box>
          <FormControl>
            <Select
              multiple
              displayEmpty
              value={selectedOriginCountries}
              open={originCountriesSelectOpen}
              onOpen={handleOriginCountriesSelectOpen}
              onClose={handleOriginCountriesSelectClose}
              onChange={(event) =>
                handleOriginCountriesChange(event.target.value as string[])
              }
              renderValue={() => {
                if (selectedOriginCountries.length === 0) {
                  return 'Please Select'
                }

                if (
                  selectedOriginCountries.length === originCountryOptions.length
                ) {
                  return 'All'
                }

                return (
                  <Typography
                    sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                    variant="bodySmall"
                    color={theme.colors.WHITE_60}
                  >
                    {selectedOriginCountries.join(', ')}
                  </Typography>
                )
              }}
            >
              <SelectCheckboxItem
                id="all"
                onClick={toggleSelectAllOriginCountries}
                checked={isAllOriginCountriesSelected}
                indeterminate={isOriginCountriesIndeterminate}
              >
                All
              </SelectCheckboxItem>
              {originCountryOptions.map((option) => (
                <SelectCheckboxItem
                  key={option}
                  value={option}
                  checked={selectedOriginCountries.indexOf(option) > -1}
                >
                  {option}
                </SelectCheckboxItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'auto 1fr',
            alignItems: 'center',
            gap: 2,
            pr: 2,
            borderRight: `1px solid ${theme.colors.WHITE_40}`,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <SvgIcon
              sx={{ width: '1rem', height: '1rem' }}
              component={TargetIcon}
              inheritViewBox
            />
            <Typography
              variant="textSmallImportant"
              sx={{ color: theme.colors.WHITE }}
            >
              Target Country
            </Typography>
          </Box>
          <FormControl>
            <Select
              multiple
              displayEmpty
              value={selectedTargetCountries}
              open={targetCountriesSelectOpen}
              onOpen={handleTargetCountriesSelectOpen}
              onClose={handleTargetCountriesSelectClose}
              onChange={(event) =>
                handleTargetCountriesChange(event.target.value as string[])
              }
              renderValue={() => {
                if (selectedTargetCountries.length === 0) {
                  return 'Please Select'
                }

                if (
                  selectedTargetCountries.length === targetCountryOptions.length
                ) {
                  return 'All'
                }

                return (
                  <Typography
                    sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                    variant="bodySmall"
                    color={theme.colors.WHITE_60}
                  >
                    {selectedTargetCountries.join(', ')}
                  </Typography>
                )
              }}
            >
              <SelectCheckboxItem
                id="all"
                onClick={toggleSelectAllTargetCountries}
                checked={isAllTargetCountriesSelected}
                indeterminate={isTargetCountriesIndeterminate}
              >
                All
              </SelectCheckboxItem>
              {targetCountryOptions.map((option) => (
                <SelectCheckboxItem
                  key={option}
                  value={option}
                  checked={selectedTargetCountries.indexOf(option) > -1}
                >
                  {option}
                </SelectCheckboxItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'auto 1fr',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <SvgIcon
              sx={{ width: '1rem', height: '1rem' }}
              component={TargetIcon}
              inheritViewBox
            />
            <Typography
              variant="textSmallImportant"
              sx={{ color: theme.colors.WHITE }}
            >
              Target Industry
            </Typography>
          </Box>
          <FormControl>
            <Select
              multiple
              displayEmpty
              value={selectedTargetIndustries}
              open={targetIndustriesSelectOpen}
              onOpen={handleTargetIndustriesSelectOpen}
              onClose={handleTargetIndustriesSelectClose}
              onChange={(event) =>
                handleTargetIndustriesChange(event.target.value as string[])
              }
              renderValue={() => {
                if (selectedTargetIndustries.length === 0) {
                  return 'Please Select'
                }

                if (
                  selectedTargetIndustries.length ===
                  targetIndustryOptions.length
                ) {
                  return 'All'
                }

                return (
                  <Typography
                    sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                    variant="bodySmall"
                    color={theme.colors.WHITE_60}
                  >
                    {selectedTargetIndustries.join(', ')}
                  </Typography>
                )
              }}
            >
              <SelectCheckboxItem
                id="all"
                onClick={toggleSelectAllTargetIndustries}
                checked={isAllTargetIndustriesSelected}
                indeterminate={isTargetIndustriesIndeterminate}
              >
                All
              </SelectCheckboxItem>
              {targetIndustryOptions.map((option) => (
                <SelectCheckboxItem
                  key={option}
                  value={option}
                  checked={selectedTargetIndustries.indexOf(option) > -1}
                >
                  {option}
                </SelectCheckboxItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      {showTags && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', flexGrow: 1, gap: 1, flexWrap: 'wrap' }}>
            {!isAllOriginCountriesSelected &&
              selectedOriginCountries.map((originCountry) => (
                <RemovableLabel
                  key={originCountry}
                  size="small"
                  handleRemove={() =>
                    handleRemoveOriginCountryByTag(originCountry)
                  }
                >
                  <SvgIcon
                    sx={{ width: '1rem', height: '1rem' }}
                    component={LocationIcon}
                    inheritViewBox
                  />
                  <Typography
                    variant="textSmallImportant"
                    color={theme.colors.WHITE_60}
                  >
                    {originCountry}
                  </Typography>
                </RemovableLabel>
              ))}
            {!isAllTargetCountriesSelected &&
              selectedTargetCountries.map((targetCountry) => (
                <RemovableLabel
                  key={targetCountry}
                  color={theme.colors.PRIMARY_20}
                  size="small"
                  handleRemove={() =>
                    handleRemoveTargetCountryByTag(targetCountry)
                  }
                >
                  <SvgIcon
                    sx={{ width: '1rem', height: '1rem' }}
                    component={TargetIcon}
                    inheritViewBox
                  />
                  <Typography
                    variant="textSmallImportant"
                    color={theme.colors.WHITE_60}
                  >
                    {targetCountry}
                  </Typography>
                </RemovableLabel>
              ))}
            {!isAllTargetIndustriesSelected &&
              selectedTargetIndustries.map((targetIndustry) => (
                <RemovableLabel
                  key={targetIndustry}
                  color={theme.colors.SECONDARY_20}
                  size="small"
                  handleRemove={() =>
                    handleRemoveTargetIndustryByTag(targetIndustry)
                  }
                >
                  <SvgIcon
                    sx={{ width: '1rem', height: '1rem' }}
                    component={TargetIcon}
                    inheritViewBox
                  />
                  <Typography
                    variant="textSmallImportant"
                    color={theme.colors.WHITE_60}
                  >
                    {targetIndustry}
                  </Typography>
                </RemovableLabel>
              ))}
          </Box>
          <Box sx={{ display: 'flex', flexShrink: 0, alignItems: 'end' }}>
            <Button variant="textColor" onClick={handleFilterReset}>
              {t('resetCta', { ns: 'component' })}
            </Button>
          </Box>
        </Box>
      )}
    </Stack>
  )
}
