import { Box, Stack } from '@mui/material'
import { CodeBlock } from 'components/CodeBlock/CodeBlock'
import { Description } from 'components/Documents/Description'
import { H1Title } from 'components/Documents/H1Title'
import { H2ColorTitle } from 'components/Documents/H2ColorTitle'
import { H2Title } from 'components/Documents/H2Title'
import { TocLink } from 'components/Documents/TocLink'
import { TocList } from 'components/Documents/TocList'
import { InlineBlock } from 'components/InlineBlock/InlineBlock'
import { ListItem, UnorderedList } from 'components/List/List'
import { DocumentContentWidth, ScrollableContainerId } from 'constants/document'
import { getPageTitleAPIDocumentByTitle } from 'constants/pageTitle'
import {
  API_DOC_V2_SAMPLE_TITLE,
  API_DOC_V2_TITLE,
} from 'pages/APIDocumentPage/title'
import { Helmet } from 'react-helmet'

const EXAMPLE_REQUEST = `curl "https://api.threatvision.org/api/v2/samples/364f38b48565814b576f482c1e0eb4c8d58effcd033fd45136ee00640a2b5321/reports" \\
  -H "Authorization: Bearer YOUR_ACCESS_TOKEN"
`

const EXAMPLE_RESPONSE_BODY = `{
  "success":true,
  "id":"364f38b48565814b576f482c1e0eb4c8d58effcd033fd45136ee00640a2b5321",
  "reports":
    [
      {
        "title":"Flash Report 20230914 - 繁體中文版",
        "date":1694620800,
        "type":"flash",
        "alias":"flash_report-20230914021756",
        "type_name":"Flash Report",
        "adversaries":["Polaris"],
        "malwares":["MiniPlug"],
        "targeted_countries":["Taiwan"],
        "targeted_industries":[],
        "capability":[],
        "digest":"近期，TeamT5 察知 Polaris 攻擊台灣單位。在這起攻擊中，我們發現 Polaris 的專屬遠端存取木馬程式 MiniPlug。",
        "pdf_url":"https://api.threatvision.org/api/v2/reports/flash_report-20230914021756.pdf",
        "stix_url":"https://api.threatvision.org/api/v2/reports/flash_report-20230914021756.stix" 
      }
    ]
}
`

const tocList = [
  { id: 'api-endpoint', text: 'API Endpoint' },
  { id: 'url-query-parameters', text: 'URL Query Parameters' },
  { id: 'response-format', text: 'Response Format' },
  { id: 'example-request', text: 'Example Request (curl)' },
  { id: 'example-response-body', text: 'Example Response Body' },
]

const TITLE = 'Reports'

export const APIDocumentVersionTwoSampleReports = () => (
  <>
    <Helmet>
      <title>
        {getPageTitleAPIDocumentByTitle(
          `${TITLE} - ${API_DOC_V2_SAMPLE_TITLE} - ${API_DOC_V2_TITLE}`
        )}
      </title>
    </Helmet>
    <Box sx={{ display: 'flex' }}>
      <Stack
        id={ScrollableContainerId.TOP_ID}
        sx={{
          width: DocumentContentWidth.WIDTH,
          maxWidth: DocumentContentWidth.MAX_WIDTH,
          minWidth: DocumentContentWidth.MIN_WIDTH,
          px: 10,
          py: 4,
          gap: 2,
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        <H1Title title={API_DOC_V2_TITLE} />
        <H2ColorTitle title={API_DOC_V2_SAMPLE_TITLE} />
        <H2Title title={TITLE} />
        <Description>
          Related reports written by TeamT5 analyst’s team would be retrieved,
          so users can learn more about the sample itself and the campaigns that
          it’s relevant to.
        </Description>
        <TocLink text="API Endpoint" hash="api-endpoint" />
        <Description>
          GET{' '}
          <InlineBlock text="https://api.threatvision.org/api/v2/samples/:sha256/reports" />
        </Description>
        <TocLink text="URL Query Parameters" hash="url-query-parameters" />
        <UnorderedList>
          <ListItem>
            offset (optional)
            <UnorderedList>
              <ListItem>
                Number of results to skip.
                <br />
                Allows you to paginate over the results.
              </ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <TocLink text="Response Format" hash="response-format" />
        <Description>JSON object, with following keys:</Description>
        <UnorderedList>
          <ListItem>
            success
            <UnorderedList>
              <ListItem>The response status</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            id
            <UnorderedList>
              <ListItem>The sha256 for sample</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            reports
            <br />
            <Description>
              Array of json objects, with following keys:
            </Description>
            <UnorderedList>
              <ListItem>title</ListItem>
              <ListItem>date</ListItem>
              <ListItem>type</ListItem>
              <ListItem>alias</ListItem>
              <ListItem>type_name</ListItem>
              <ListItem>adversaries</ListItem>
              <ListItem>malwares</ListItem>
              <ListItem>targeted_countries</ListItem>
              <ListItem>targeted_industries</ListItem>
              <ListItem>
                capability: Array of METRE serial for capability.
              </ListItem>
              <ListItem>digest</ListItem>
              <ListItem>pdf_url</ListItem>
              <ListItem>stix_url</ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <TocLink text="Example Request (curl)" hash="example-request" />
        <CodeBlock code={EXAMPLE_REQUEST} />
        <TocLink text="Example Response Body" hash="example-response-body" />
        <CodeBlock code={EXAMPLE_RESPONSE_BODY} />
        <Box id={ScrollableContainerId.BOTTOM_ID} />
      </Stack>
      <TocList tocList={tocList} />
    </Box>
  </>
)
