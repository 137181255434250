import {
  Box,
  Stack,
  SvgIcon,
  TableBody,
  TableCell,
  TableCellProps,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material'
import { ReactComponent as ArrowForwardIcon } from 'assets/basicIcons/arrowForward.svg'
import { ReactComponent as InfoIcon } from 'assets/basicIcons/info.svg'
import { ReactComponent as ReportIcon } from 'assets/report/icons/report.svg'
import { Button } from 'components/Button/Button'
import { NewLabel } from 'components/Label/Label'
import { ExternalLink } from 'components/Link/Link'
import { Tooltip } from 'components/Tooltip/Tooltip'
import { REPORT_DETAIL_ROUTE, REPORTS_ROUTE } from 'constants/routeParams'
import { SearchParamKey } from 'constants/searchParamKeys'
import { useDateTime } from 'hooks/useDatetime'
import { useAppSelector } from 'hooks/useReduxHooks'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { selectPMReportDetail } from 'store/slices/report'
import { IPMReportDetail } from 'store/types/entityTypes/report'

import { DescriptionDrawer } from './DescriptionDrawer'
import { ExploitedDrawer } from './ExploitedDrawer'
import { PatchDrawer } from './PatchDrawer'
import type {
  TFilterTextMap,
  TOrder,
  TPMReportDetailOrderKeys,
} from './PMReportDetailTable'
import {
  filterPMReportDetail,
  getPMReportDetailComparatorMap,
} from './PMReportDetailTable.config'
import { ProductDrawer } from './ProductDrawer'
import { PubliclyDisclosedDrawer } from './PubliclyDisclosedDrawer'

const checkHasExternalLink = (id: string): boolean => id.slice(0, 3) === 'CVE'

const PMReportDetailTableBodyCell = ({
  children,
  sx,
  ...otherProps
}: TableCellProps) => {
  const theme = useTheme()

  return (
    <TableCell
      sx={{
        height: '2.25rem',
        px: 4,
        py: 2,
        borderColor: theme.colors.WHITE_20,
        borderRight: `1px solid ${theme.colors.WHITE_20}`,
        ...sx,
      }}
      {...otherProps}
    >
      <Typography variant="body" sx={{ color: theme.colors.WHITE }}>
        {children}
      </Typography>
    </TableCell>
  )
}

interface IPMReportDetailTableBodyProps {
  order: TOrder
  orderBy: TPMReportDetailOrderKeys
  filterTextMap: TFilterTextMap
}

const DESCRIPTION_MAX_LENGTH = 50
const PRODUCT_MAX_LENGTH = 30

export const getDescriptionByTitleAndDetail = (
  reportDetail: IPMReportDetail
): string => {
  const titleLength = reportDetail.description.title
    ? reportDetail.description.title.length
    : 0
  const detailLength = reportDetail.description.detail
    ? reportDetail.description.detail.length
    : 0

  const title = reportDetail.description.title || ''
  const detail = reportDetail.description.detail || ''

  let description = '--'
  if (titleLength + detailLength < DESCRIPTION_MAX_LENGTH) {
    description = `${title} ${detail}`
  } else {
    description = `${`${title} ${detail}`.slice(0, DESCRIPTION_MAX_LENGTH)}...`
  }

  return description
}

export const PMReportDetailTableBody = ({
  order,
  orderBy,
  filterTextMap,
}: IPMReportDetailTableBodyProps) => {
  const theme = useTheme()
  const { getDateStringByTimestampsInSeconds } = useDateTime()
  const { t } = useTranslation(['report'])
  const pmReportDetail = useAppSelector(selectPMReportDetail)

  const filteredPMReportDetails = filterPMReportDetail({
    pmReportDetail,
    filterTextMap,
  })

  const sortedPMReportDetails = filteredPMReportDetails.sort(
    getPMReportDetailComparatorMap(order)[orderBy]
  )

  const [productDrawerOpen, setProductDrawerOpen] = useState(false)
  const [descriptionDrawerOpen, setDescriptionDrawerOpen] = useState(false)
  const [publiclyDisclosedDrawerOpen, setPubliclyDisclosedDrawerOpen] =
    useState(false)
  const [exploitedDrawerOpen, setExploitedDrawerOpen] = useState(false)
  const [patchDrawerOpen, setPatchDrawerOpen] = useState(false)
  const [selectedCVE, setSelectedCVE] = useState('')

  const handleDescriptionDrawerOpen = (cve: string) => {
    setDescriptionDrawerOpen(true)
    setSelectedCVE(cve)
  }

  const handleDescriptionDrawerClose = () => {
    setDescriptionDrawerOpen(false)
    setSelectedCVE('')
  }

  const handlePubliclyDisclosedDrawerOpen = (cve: string) => {
    setPubliclyDisclosedDrawerOpen(true)
    setSelectedCVE(cve)
  }

  const handlePubliclyDisclosedDrawerClose = () => {
    setPubliclyDisclosedDrawerOpen(false)
    setSelectedCVE('')
  }

  const handleExploitedDrawerOpen = (cve: string) => {
    setExploitedDrawerOpen(true)
    setSelectedCVE(cve)
  }

  const handleExploitedDrawerClose = () => {
    setExploitedDrawerOpen(false)
    setSelectedCVE('')
  }

  const handlePatchDrawerOpen = (cve: string) => {
    setPatchDrawerOpen(true)
    setSelectedCVE(cve)
  }

  const handlePatchDrawerClose = () => {
    setPatchDrawerOpen(false)
    setSelectedCVE('')
  }

  const handleProductDrawerOpen = (cve: string) => {
    setProductDrawerOpen(true)
    setSelectedCVE(cve)
  }

  const handleProductDrawerClose = () => {
    setProductDrawerOpen(false)
    setSelectedCVE('')
  }

  return (
    <TableBody>
      {sortedPMReportDetails.map((reportDetail) => {
        const description = getDescriptionByTitleAndDetail(reportDetail)

        return (
          <TableRow key={reportDetail.id} hover>
            <PMReportDetailTableBodyCell
              sx={{
                borderLeft: 'none',
                position: 'sticky',
                left: 0,
                background: theme.colors.BLACK_90,
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Stack sx={{ gap: 1 }}>
                  {reportDetail.isNew && <NewLabel />}
                  {checkHasExternalLink(reportDetail.id) ? (
                    <ExternalLink
                      href={`https://cve.mitre.org/cgi-bin/cvename.cgi?name=${reportDetail.id}`}
                    >
                      {reportDetail.id}
                    </ExternalLink>
                  ) : (
                    <Typography
                      variant="titleSmall"
                      sx={{ color: theme.colors.TEXT_LINK_NORMAL }}
                    >
                      {reportDetail.id}
                    </Typography>
                  )}
                </Stack>
                {reportDetail.reportAliases !== null &&
                  reportDetail.reportAliases.length !== 0 && (
                    <Link
                      to={`/${REPORTS_ROUTE}/${REPORT_DETAIL_ROUTE}?${SearchParamKey.REPORT_ALIAS}=${reportDetail.reportAliases[0]}`}
                    >
                      <SvgIcon component={ReportIcon} inheritViewBox />
                    </Link>
                  )}
              </Box>
            </PMReportDetailTableBodyCell>
            <PMReportDetailTableBodyCell>
              <Typography variant="body">{reportDetail.vendor}</Typography>
            </PMReportDetailTableBodyCell>
            <PMReportDetailTableBodyCell>
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                {reportDetail.product ? (
                  reportDetail.product.length > PRODUCT_MAX_LENGTH ? (
                    <Tooltip title={reportDetail.product}>
                      <Typography variant="body">
                        {reportDetail.product.slice(0, PRODUCT_MAX_LENGTH)}...
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography variant="body">
                      {reportDetail.product}
                    </Typography>
                  )
                ) : (
                  <Typography variant="body">--</Typography>
                )}

                {reportDetail.productList.length > 0 && (
                  <Box>
                    <Button
                      sx={{
                        minWidth: 0,
                        p: 0,
                        borderColor: theme.colors.WHITE_60,
                      }}
                      onClick={() => handleProductDrawerOpen(reportDetail.id)}
                    >
                      <SvgIcon
                        component={ArrowForwardIcon}
                        inheritViewBox
                        sx={{ path: { fill: theme.colors.WHITE_60 } }}
                      />
                    </Button>
                  </Box>
                )}
              </Box>
            </PMReportDetailTableBodyCell>
            <PMReportDetailTableBodyCell>
              {reportDetail.cvss ? (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography variant="body">{reportDetail.cvss}</Typography>
                  <Tooltip title={reportDetail.cvssVector}>
                    <SvgIcon component={InfoIcon} inheritViewBox />
                  </Tooltip>
                </Box>
              ) : (
                <Typography variant="body">N/A</Typography>
              )}
            </PMReportDetailTableBodyCell>
            <PMReportDetailTableBodyCell>
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="body">{description}</Typography>
                <Box>
                  <Button
                    sx={{
                      minWidth: 0,
                      p: 0,
                      borderColor: theme.colors.WHITE_60,
                    }}
                    onClick={() => handleDescriptionDrawerOpen(reportDetail.id)}
                  >
                    <SvgIcon
                      component={ArrowForwardIcon}
                      inheritViewBox
                      sx={{ path: { fill: theme.colors.WHITE_60 } }}
                    />
                  </Button>
                </Box>
              </Box>
            </PMReportDetailTableBodyCell>
            <PMReportDetailTableBodyCell sx={{ textAlign: 'center' }}>
              <Typography
                variant="body"
                sx={{
                  textTransform: 'none',
                  color: theme.colors.WHITE,
                }}
              >
                {reportDetail.threatLevel}
              </Typography>
            </PMReportDetailTableBodyCell>
            <PMReportDetailTableBodyCell sx={{ textAlign: 'center' }}>
              {reportDetail.poc && reportDetail.poc.length !== 0 ? (
                <Button
                  endIcon={
                    <SvgIcon
                      component={ArrowForwardIcon}
                      inheritViewBox
                      sx={{ color: theme.colors.WHITE_60 }}
                    />
                  }
                  onClick={() =>
                    handlePubliclyDisclosedDrawerOpen(reportDetail.id)
                  }
                >
                  {reportDetail.publiclyDisclosed ? 'YES' : 'NO'}
                </Button>
              ) : (
                <Typography variant="body" sx={{ color: theme.colors.WHITE }}>
                  {reportDetail.publiclyDisclosed ? 'YES' : 'NO'}
                </Typography>
              )}
            </PMReportDetailTableBodyCell>
            <PMReportDetailTableBodyCell sx={{ textAlign: 'center' }}>
              {(reportDetail.maliciousFiles &&
                reportDetail.maliciousFiles.length !== 0) ||
              (reportDetail.suspiciousDomains &&
                reportDetail.suspiciousDomains.length !== 0) ||
              (reportDetail.suspiciousIps &&
                reportDetail.suspiciousIps.length > 0) ? (
                <Button
                  endIcon={
                    <SvgIcon
                      component={ArrowForwardIcon}
                      inheritViewBox
                      sx={{ color: theme.colors.WHITE_60 }}
                    />
                  }
                  onClick={() => handleExploitedDrawerOpen(reportDetail.id)}
                >
                  {reportDetail.exploited ? 'YES' : 'NO'}
                </Button>
              ) : (
                <Typography variant="body" sx={{ color: theme.colors.WHITE }}>
                  {reportDetail.exploited ? 'YES' : 'NO'}
                </Typography>
              )}
            </PMReportDetailTableBodyCell>
            <PMReportDetailTableBodyCell sx={{ textAlign: 'center' }}>
              <Button
                endIcon={
                  <SvgIcon component={ArrowForwardIcon} inheritViewBox />
                }
                onClick={() => handlePatchDrawerOpen(reportDetail.id)}
                disabled={reportDetail.patch.length === 0}
              >
                {t('pmReportsDetail.PatchCheckCta', { ns: 'report' })}
              </Button>
            </PMReportDetailTableBodyCell>
            <PMReportDetailTableBodyCell sx={{ borderRight: 'none' }}>
              <Typography variant="body">
                {getDateStringByTimestampsInSeconds(reportDetail.updatedAt)}
              </Typography>
            </PMReportDetailTableBodyCell>
          </TableRow>
        )
      })}
      <ProductDrawer
        selectedCVE={selectedCVE}
        pmReportDetail={pmReportDetail}
        productDrawerOpen={productDrawerOpen}
        onClose={handleProductDrawerClose}
      />
      <DescriptionDrawer
        selectedCVE={selectedCVE}
        pmReportDetail={pmReportDetail}
        descriptionDrawerOpen={descriptionDrawerOpen}
        onClose={handleDescriptionDrawerClose}
      />
      <PubliclyDisclosedDrawer
        selectedCVE={selectedCVE}
        pmReportDetail={pmReportDetail}
        publiclyDisclosedDrawerOpen={publiclyDisclosedDrawerOpen}
        onClose={handlePubliclyDisclosedDrawerClose}
      />
      <ExploitedDrawer
        selectedCVE={selectedCVE}
        pmReportDetail={pmReportDetail}
        exploitedDrawerOpen={exploitedDrawerOpen}
        onClose={handleExploitedDrawerClose}
      />
      <PatchDrawer
        selectedCVE={selectedCVE}
        pmReportDetail={pmReportDetail}
        patchDrawerOpen={patchDrawerOpen}
        onClose={handlePatchDrawerClose}
      />
    </TableBody>
  )
}
