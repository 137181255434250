import { Box, Stack } from '@mui/material'
import { CodeBlock } from 'components/CodeBlock/CodeBlock'
import { Description } from 'components/Documents/Description'
import { H1Title } from 'components/Documents/H1Title'
import { H2ColorTitle } from 'components/Documents/H2ColorTitle'
import { H2Title } from 'components/Documents/H2Title'
import { TocLink } from 'components/Documents/TocLink'
import { TocList } from 'components/Documents/TocList'
import { InlineBlock } from 'components/InlineBlock/InlineBlock'
import { ListItem, UnorderedList } from 'components/List/List'
import { DocumentContentWidth, ScrollableContainerId } from 'constants/document'
import { getPageTitleAPIDocumentByTitle } from 'constants/pageTitle'
import { Helmet } from 'react-helmet'

import { API_DOC_V2_INTEL_REPORTS_TITLE, API_DOC_V2_TITLE } from '../title'

const EXAMPLE_REQUEST = `curl "https://api.threatvision.org/api/v2/reports" \\
  -H "Authorization: Bearer YOUR_ACCESS_TOKEN"
`

const EXAMPLE_RESPONSE_BODY = `{
  "success": true,
  "reports": [
    {
      "title": "Flash Report 20230929",
      "date": 1695916800,
      "type": "flash",
      "alias": "flash_report-20230928074006",
      "type_name": "Flash Report",
      "adversaries": ["SLIME13"],
      "malwares": ["SoftEther VPN"],
      "targeted_countries": ["Taiwan"],
      "targeted_industries": ["Accounting"],
      "capability": ["T1572","T1573","T1132","T1569","T1569.002"],
      "digest": "Recently, TeamT5’s telemetry intercepted a SLIME13’s attack against accounting entities in Taiwan...",
      "pdf_url": "https://api.threatvision.org/api/v2/reports/flash_report-20230928074006.pdf",
      "stix_url": "https://api.threatvision.org/api/v2/reports/flash_report-20230928074006.stix"}
  ],...
}
`

const tocList = [
  { id: 'api-endpoint', text: 'API Endpoint' },
  { id: 'url-query-parameters', text: 'URL Query Parameters' },
  { id: 'response-format', text: 'Response Format' },
  { id: 'example-request', text: 'Example Request (curl)' },
  { id: 'example-response-body', text: 'Example Response Body' },
]

const TITLE = 'List Reports'

export const APIDocumentVersionTwoIntelligenceReportListReports = () => (
  <>
    <Helmet>
      <title>
        {getPageTitleAPIDocumentByTitle(
          `${TITLE} - ${API_DOC_V2_INTEL_REPORTS_TITLE} - ${API_DOC_V2_TITLE}`
        )}
      </title>
    </Helmet>
    <Box sx={{ display: 'flex' }}>
      <Stack
        id={ScrollableContainerId.TOP_ID}
        sx={{
          width: DocumentContentWidth.WIDTH,
          maxWidth: DocumentContentWidth.MAX_WIDTH,
          minWidth: DocumentContentWidth.MIN_WIDTH,
          px: 10,
          py: 4,
          gap: 2,
          flexGrow: 1,
          flexBasis: 0,
          overflow: 'auto',
        }}
      >
        <H1Title title={API_DOC_V2_TITLE} />
        <H2ColorTitle title={API_DOC_V2_INTEL_REPORTS_TITLE} />
        <H2Title title={TITLE} />
        <UnorderedList>
          <ListItem>
            List the first 10 readable reports that match given criteria.
          </ListItem>
          <ListItem>Reports are sorted by the newest publish date.</ListItem>
        </UnorderedList>
        <TocLink text="API Endpoint" hash="api-endpoint" />
        <Description>
          GET <InlineBlock text="https://api.threatvision.org/api/v2/reports" />
        </Description>
        <TocLink text="URL Query Parameters" hash="url-query-parameters" />
        <UnorderedList>
          <ListItem>
            query (optional)
            <UnorderedList>
              <ListItem>Keywords to filter reports</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            types[] (optional, multiple)
            <UnorderedList>
              <ListItem>
                Available report types
                <UnorderedList>
                  <ListItem>
                    <InlineBlock text="advanced" />: Campaign Tracking Report
                  </ListItem>
                  <ListItem>
                    <InlineBlock text="bi_weekly" />: Cyber Affairs Report
                  </ListItem>
                  <ListItem>
                    <InlineBlock text="monthly" />: Monthly Report
                  </ListItem>
                  <ListItem>
                    <InlineBlock text="flash" />: Flash Report
                  </ListItem>
                  <ListItem>
                    <InlineBlock text="vulnerability_insights" />: Vulnerability
                    Insights Report
                  </ListItem>
                  <ListItem>
                    <InlineBlock text="on_demand" />: Miscellaneous
                  </ListItem>
                </UnorderedList>
              </ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            date[from] (optional)
            <UnorderedList>
              <ListItem>
                Only match reports published after this date, in unix timestamp
              </ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            date[to] (optional)
            <UnorderedList>
              <ListItem>
                Only match reports published before this date, in unix timestamp
              </ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            tags[] (optional)
            <UnorderedList>
              <ListItem>Only match reports with these tags</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            offset (optional)
            <UnorderedList>
              <ListItem>
                Number of results to skip
                <br />
                Allows you to paginate over the results
              </ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <TocLink text="Response Format" hash="response-format" />
        <Description>
          JSON object, with following keys:
          <UnorderedList>
            <ListItem>
              success
              <UnorderedList>
                <ListItem>The response status</ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>
              reports
              <UnorderedList>
                <ListItem>
                  An array of matching reports, each report contains following
                  keys:
                  <UnorderedList>
                    <ListItem>title: the title of the report</ListItem>
                    <ListItem>
                      date: the release time in unix timestamp of the report
                    </ListItem>
                    <ListItem>
                      type: [&quot;flash&quot;, &quot;bi_weekly&quot;,
                      &quot;monthly&quot;, &quot;advanced&quot;,
                      &quot;vulnerability_insights&quot;, &quot;on_demand&quot;]
                    </ListItem>
                    <ListItem>alias: the unique key of the report</ListItem>
                    <ListItem>type_name: common name of report type</ListItem>
                    <ListItem>
                      adversaries: adversaries related to the report
                    </ListItem>
                    <ListItem>malwares: malware related to the report</ListItem>
                    <ListItem>
                      capability: capabilities related to the report
                    </ListItem>
                    <ListItem>
                      targeted_countries: countries that were attacked in a
                      campaign mentioned in the report
                    </ListItem>
                    <ListItem>
                      targeted_industries: industries that were attacked in a
                      campaign mentioned in the report
                    </ListItem>
                    <ListItem>digest: short digest of the report</ListItem>
                    <ListItem>pdf_url: url to download the report</ListItem>
                    <ListItem>
                      stix_url: url to download indicators in STIX format
                    </ListItem>
                  </UnorderedList>
                </ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>
        </Description>
        <TocLink text="Example Request (curl)" hash="example-request" />
        <CodeBlock code={EXAMPLE_REQUEST} />
        <TocLink text="Example Response Body" hash="example-response-body" />
        <CodeBlock code={EXAMPLE_RESPONSE_BODY} />
        <Box id={ScrollableContainerId.BOTTOM_ID} />
      </Stack>
      <TocList tocList={tocList} />
    </Box>
  </>
)
