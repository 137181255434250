import { IDdwCds, IDdwCl } from 'store/types/entityTypes/ddw'

const DEMO_KEYWORD_0 = 'example'
const DEMO_KEYWORD_1 = 'example.com'

export const oneTimeReportMetaList = [
  {
    keyword: DEMO_KEYWORD_0,
    clCount: 3,
    cdsCount: 6,
  },
  {
    keyword: DEMO_KEYWORD_1,
    clCount: 200,
    cdsCount: 200,
  },
]

export const periodReportMetaList = [
  {
    keyword: DEMO_KEYWORD_0,
    clCount: 0,
    cdsCount: 4,
  },
  {
    keyword: DEMO_KEYWORD_1,
    clCount: 6,
    cdsCount: 3,
  },
]

export const periodReportList = [
  {
    reportId: '82e0d626-7e24-4a05-81b3-631e28e640d9',
    contractId: '2f86e695-5108-4d1d-8e81-d18543dd4608',
    reportStartAt: 1718812800000.0,
    reportEndAt: 1719417599000.0,
    reportCount: 13,
  },
]

export const oneTimeClDataMap: Record<string, IDdwCl[]> = {
  [DEMO_KEYWORD_0]: [
    {
      id: 'a',
      leakedFrom: 'cit0day',
      leakedAt: 1725148800000,
      domain: 'example.com',
      email: 'test01@example.com',
      password: 'f***********',
    },
    {
      id: 'b',
      leakedFrom: 'country',
      leakedAt: 1725148800000,
      domain: 'example.com',
      email: 'test02@example.com',
      password: 'p***********',
    },
    {
      id: 'c',
      leakedFrom: 'city',
      leakedAt: 1714521600000,
      domain: 'example.com',
      email: 'test03@example.com',
      password: 'w***********',
    },
  ],
  [DEMO_KEYWORD_1]: [
    {
      id: 'a',
      leakedFrom: 'parkif0000.com',
      leakedAt: 1714521600000,
      domain: 'example.com',
      email: 'test01@example.com',
      password: 'a***********',
    },
    {
      id: 'b',
      leakedFrom: 'parkif0000.com',
      leakedAt: 1714521600000,
      domain: 'example.com',
      email: 'test02@example.com',
      password: 'g***********',
    },
    {
      id: 'c',
      leakedFrom: 'cit0day',
      leakedAt: 1709251200000,
      domain: 'example.com',
      email: 'test03@example.com',
      password: 'o***********',
    },
    {
      id: 'd',
      leakedFrom: 'Dubsmash',
      leakedAt: 1709251200000,
      domain: 'example.com',
      email: 'test04@example.com',
      password: '6***********',
    },
    {
      id: 'e',
      leakedFrom: 'Dubsmash',
      leakedAt: 1709251200000,
      domain: 'example.com',
      email: 'test05@example.com',
      password: 'Q***********',
    },
    {
      id: 'f',
      leakedFrom: 'Dubsmash',
      leakedAt: 1709251200000,
      domain: 'example.com',
      email: 'test06@example.com',
      password: 'F***********',
    },
    {
      id: 'g',
      leakedFrom: 'employee',
      leakedAt: 1706745600000,
      domain: 'example.com',
      email: 'test07@example.com',
      password: 'e***********',
    },
    {
      id: 'h',
      leakedFrom: 'you12',
      leakedAt: 1706745600000,
      domain: 'example.com',
      email: 'test08@example.com',
      password: 'a***********',
    },
    {
      id: 'i',
      leakedFrom: 'letaa',
      leakedAt: 1706745600000,
      domain: 'example.com',
      email: 'test09@example.com',
      password: 'd***********',
    },
    {
      id: 'j',
      leakedFrom: 'Dubsmash',
      leakedAt: 1706745600000,
      domain: 'example.com',
      email: 'test10@example.com',
      password: 'l***********',
    },
  ],
}

export const oneTimeCdsDataMap: Record<string, IDdwCds[]> = {
  [DEMO_KEYWORD_0]: [
    {
      id: 'a',
      host: 'https://www.example.com',
      user: 'test01@example.com',
      password: 'A***********',
      leakedAt: 1714771920000,
      regdate: null,
      ip: '111.**.**.11',
      username: 'U***',
      computername: null,
    },
    {
      id: 'b',
      host: 'https://www.example.com',
      user: 'test02@example.com',
      password: 'A***********',
      leakedAt: 1701375840000,
      regdate: null,
      ip: '111.***.***.11',
      username: 'l*******',
      computername: null,
    },
    {
      id: 'c',
      host: 'https://www.example.com',
      user: 'test03@example.com',
      password: 'C***********',
      leakedAt: 1694340120000,
      regdate: null,
      ip: '111.***.***.11',
      username: 'U**',
      computername: null,
    },
    {
      id: 'd',
      host: 'https://www.example.com',
      user: 'test04@example.com',
      password: '6***********',
      leakedAt: 1693437480000,
      regdate: null,
      ip: '111.***.***.111',
      username: 'C******',
      computername: null,
    },
    {
      id: 'e',
      host: 'https://www.example.com',
      user: 'test05@example.com',
      password: 'd***********',
      leakedAt: 1682349780000,
      regdate: null,
      ip: '111.***.***.111',
      username: 'm*****',
      computername: null,
    },
    {
      id: 'f',
      host: 'https://www.example.com',
      user: 'test06@example.com',
      password: 'o***********',
      leakedAt: 1677385680000,
      regdate: null,
      ip: '111.***.***.111',
      username: 'm********',
      computername: null,
    },
  ],
  [DEMO_KEYWORD_1]: [
    {
      id: 'a',
      host: 'https://www.example.com',
      user: 'test01@example.com',
      password: 'A***********',
      leakedAt: 1730626320000,
      regdate: null,
      ip: '11.***.***.11',
      username: 'J*******',
      computername: null,
    },
    {
      id: 'b',
      host: 'https://www.example.com',
      user: 'test02@example.com',
      password: '6***********',
      leakedAt: 1695440580000,
      regdate: null,
      ip: '1.***.***.11',
      username: 'H*****',
      computername: null,
    },
    {
      id: 'c',
      host: 'https://www.example.com',
      user: 'test03@example.com',
      password: 'Y***********',
      leakedAt: 1695423720000,
      regdate: null,
      ip: '11.***.***.11',
      username: 'P*******',
      computername: null,
    },
    {
      id: 'd',
      host: 'https://www.example.com',
      user: 'test04@example.com',
      password: 'a***********',
      leakedAt: 1684250880000,
      regdate: null,
      ip: '111.***.***.111',
      username: 'k******',
      computername: null,
    },
    {
      id: 'e',
      host: 'https://www.example.com',
      user: 'test05@example.com',
      password: 'c***********',
      leakedAt: 1682631840000,
      regdate: null,
      ip: '111.***.***.111',
      username: 'b******',
      computername: null,
    },
    {
      id: 'f',
      host: 'https://www.example.com',
      user: 'test06@example.com',
      password: 'l***********',
      leakedAt: 1679234340000,
      regdate: null,
      ip: '111.***.***.111',
      username: 'j******',
      computername: null,
    },
    {
      id: 'g',
      host: 'https://www.example.com',
      user: 'test07@example.com',
      password: 'k***********',
      leakedAt: 1677819840000,
      regdate: null,
      ip: '11.***.**.111',
      username: 'a**************',
      computername: null,
    },
    {
      id: 'h',
      host: 'https://www.example.com',
      user: 'test08@example.com',
      password: '8***********',
      leakedAt: 1667218080000,
      regdate: null,
      ip: '111.**.**.11',
      username: 's********',
      computername: null,
    },
    {
      id: 'i',
      host: 'https://www.example.com',
      user: 'test09@example.com',
      password: 'd***********',
      leakedAt: 1663518180000,
      regdate: null,
      ip: '111.***.**.11',
      username: 'e*****',
      computername: null,
    },
    {
      id: 'j',
      host: 'https://www.example.com',
      user: 'test10@example.com',
      password: 'h***********',
      leakedAt: 1650940080000,
      regdate: null,
      ip: '111.**.***.111',
      username: 'm********',
      computername: null,
    },
  ],
}

export const periodClDataMap: Record<string, IDdwCl[]> = {
  [DEMO_KEYWORD_0]: [],
  [DEMO_KEYWORD_1]: [
    {
      id: 'a',
      leakedFrom: 'park0000.com',
      leakedAt: 1714521600000,
      domain: 'example.com',
      email: 'test01@example.com',
      password: 'a**********4',
    },
    {
      id: 'b',
      leakedFrom: 'park0000.com',
      leakedAt: 1714521600000,
      domain: 'example.com',
      email: 'test02@example.com',
      password: 'g**********1',
    },
    {
      id: 'c',
      leakedFrom: 'cit0day',
      leakedAt: 1709251200000,
      domain: 'example.com',
      email: 'test03@example.com',
      password: 'o**********7',
    },
    {
      id: 'd',
      leakedFrom: 'Dubsmash',
      leakedAt: 1709251200000,
      domain: 'example.com',
      email: 'test04@example.com',
      password: '6**********v',
    },
    {
      id: 'e',
      leakedFrom: 'Dubsmash',
      leakedAt: 1709251200000,
      domain: 'example.com',
      email: 'test05@example.com',
      password: 'Q**********c',
    },
    {
      id: 'f',
      leakedFrom: 'Dubsmash',
      leakedAt: 1709251200000,
      domain: 'example.com',
      email: 'test06@example.com',
      password: 'F**********e',
    },
  ],
}

export const periodCdsDataMap: Record<string, IDdwCds[]> = {
  [DEMO_KEYWORD_0]: [
    {
      id: 'a',
      host: 'https://www.example.com',
      user: 'test01@example.com',
      password: 'A**********d',
      leakedAt: 1714771920000,
      regdate: null,
      ip: '111.00.00.1',
      username: 'User',
      computername: null,
    },
    {
      id: 'b',
      host: 'https://www.example.com',
      user: 'test02@example.com',
      password: 'A**********e',
      leakedAt: 1701375840000,
      regdate: null,
      ip: '111.000.000.11',
      username: 'luckydog',
      computername: null,
    },
    {
      id: 'c',
      host: 'https://www.example.com',
      user: 'test03@example.com',
      password: 'C**********6',
      leakedAt: 1694340120000,
      regdate: null,
      ip: '11.000.000.11',
      username: 'User',
      computername: null,
    },
    {
      id: 'd',
      host: 'https://www.example.com',
      user: 'test04@example.com',
      password: '6**********b',
      leakedAt: 1693437480000,
      regdate: null,
      ip: '111.000.000.11',
      username: 'CathyL',
      computername: null,
    },
  ],
  [DEMO_KEYWORD_1]: [
    {
      id: 'a',
      host: 'https://www.example.com',
      user: 'test01@example.com',
      password: 'A**********x',
      leakedAt: 1730626320000,
      regdate: null,
      ip: '11.000.000.11',
      username: 'Johndoe',
      computername: null,
    },
    {
      id: 'b',
      host: 'https://www.example.com',
      user: 'test02@example.com',
      password: '6**********q',
      leakedAt: 1695440580000,
      regdate: null,
      ip: '11.000.000.11',
      username: 'Honeybee',
      computername: null,
    },
    {
      id: 'c',
      host: 'https://www.example.com',
      user: 'test03@example.com',
      password: 'Y**********3',
      leakedAt: 1695423720000,
      regdate: null,
      ip: '11.000.000.11',
      username: 'Panda',
      computername: null,
    },
  ],
}
