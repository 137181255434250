import { Box, Typography, useTheme } from '@mui/material'
import WorldMapImage from 'assets/adversary/worldMap.png'
import { useAdversaryOriginCountryPositionMap } from 'hooks/useAdversaryOriginCountries'
import { useState } from 'react'
import { IAdversary } from 'store/types/entityTypes/adversary'

interface IAdversaryMapProps {
  adversaries: IAdversary[]
  originCountryOptions: string[]
  selectedOriginCountries: string[]
  handleSelectOriginCountryByTag: (newCountry: string) => void
}

export const AdversaryMap = ({
  adversaries,
  originCountryOptions,
  selectedOriginCountries,
  handleSelectOriginCountryByTag,
}: IAdversaryMapProps) => {
  const theme = useTheme()
  const locationMap = useAdversaryOriginCountryPositionMap()

  const [hoverCountry, setHoverCountry] = useState<string>('')

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
        backgroundImage: `url(${WorldMapImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
      }}
    >
      {originCountryOptions
        .filter((originCountry) => Boolean(locationMap[originCountry]))
        .map((originCountry) => {
          const currentAdversariesCount = adversaries.filter((adversary) =>
            adversary.originCountries.includes(originCountry)
          ).length

          const isOriginCountrySelected =
            selectedOriginCountries.includes(originCountry)
          return (
            <Box
              key={originCountry}
              sx={{
                position: 'absolute',
                cursor: 'pointer',
                ...locationMap[originCountry],
              }}
              onClick={() => handleSelectOriginCountryByTag(originCountry)}
            >
              <Box
                sx={[
                  {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '1rem',
                    height: '1rem',
                    borderRadius: '50%',
                    bgcolor: isOriginCountrySelected
                      ? theme.colors.ERROR
                      : theme.colors.BLACK_85,
                  },
                  isOriginCountrySelected
                    ? {
                        '@keyframes pulse': {
                          '0%': {
                            boxShadow: `0 0 0 0 ${theme.colors.ERROR_80}`,
                          },
                          '70%': {
                            boxShadow: '0 0 0 8px rgba(0, 0, 0, 0)',
                          },
                          '100%': {
                            boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
                          },
                        },
                        '&:not(:hover)': {
                          animation: 'pulse 2s infinite',
                        },
                        '&:hover': {
                          boxShadow: `0 0 0 8px ${theme.colors.ERROR_60}`,
                        },
                      }
                    : {
                        boxShadow: `0 0 0 8px ${theme.colors.WHITE_20}`,
                        '&:hover': {
                          boxShadow: `0 0 0 8px ${theme.colors.ERROR_60}`,
                          bgcolor: theme.colors.ERROR,
                        },
                      },
                ]}
                onMouseEnter={() => setHoverCountry(originCountry)}
                onMouseLeave={() => setHoverCountry('')}
              >
                <Typography
                  variant="textSmallImportant"
                  color={theme.colors.WHITE_60}
                  sx={{ lineHeight: '0.75rem' }}
                >
                  {currentAdversariesCount}
                </Typography>
              </Box>
              {(isOriginCountrySelected || hoverCountry === originCountry) && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'absolute',
                    left: '50%',
                    transform: 'translateX(-50%)',
                  }}
                >
                  <Typography
                    variant="textSmallImportant"
                    color={theme.colors.BLACK_90}
                    sx={{
                      mt: 1,
                      px: 2,
                      borderRadius: 0.5,
                      bgcolor: theme.colors.PRIMARY,
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {originCountry}
                  </Typography>
                </Box>
              )}
            </Box>
          )
        })}
    </Box>
  )
}
