import { Box, Stack } from '@mui/material'
import { CodeBlock } from 'components/CodeBlock/CodeBlock'
import { Description } from 'components/Documents/Description'
import { H1Title } from 'components/Documents/H1Title'
import { H2ColorTitle } from 'components/Documents/H2ColorTitle'
import { TocLink } from 'components/Documents/TocLink'
import { TocList } from 'components/Documents/TocList'
import { InlineBlock } from 'components/InlineBlock/InlineBlock'
import { NumberListItem, OrderedList } from 'components/List/List'
import { DocumentContentWidth, ScrollableContainerId } from 'constants/document'
import { getPageTitleAPIDocumentByTitle } from 'constants/pageTitle'
import { Helmet } from 'react-helmet'

import { API_DOC_AUTH } from '../title'

const tocList = [{ id: 'steps', text: 'Steps' }]

const TITLE = 'Basic Auth'

const STEP2_EXAMPLE_CODE =
  "> echo -n 'YOUR_ACCESS_TOKEN' | md5sum \n d5ffae66301c07f24327cba9d5743576"
const STEP3_EXAMPLE_CODE =
  'curl "https://api.threatvision.org/api/v2/reports"  -u d5ffae66301c07f24327cba9d5743576:'

export const BasicAuth = () => (
  <>
    <Helmet>
      <title>
        {getPageTitleAPIDocumentByTitle(`${TITLE} - ${API_DOC_AUTH}`)}
      </title>
    </Helmet>
    <Box sx={{ display: 'flex' }}>
      <Stack
        id={ScrollableContainerId.TOP_ID}
        sx={{
          width: DocumentContentWidth.WIDTH,
          maxWidth: DocumentContentWidth.MAX_WIDTH,
          minWidth: DocumentContentWidth.MIN_WIDTH,
          px: 10,
          py: 4,
          gap: 2,
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        <H1Title title="Authentication" />
        <H2ColorTitle title={TITLE} />
        <Description>
          Some systems only support integration via HTTP Basic Authentication.
          To accommodate this, TeamT5 maintain compatibility with Basic Auth,
          with a small adjustment.
        </Description>
        <Description>
          After obtaining the <InlineBlock text="access_token" /> from the{' '}
          <InlineBlock text="/oauth/token" /> endpoint, you can use it for HTTP
          Basic Authentication by hashing the token with MD5. The resulting MD5
          digest can then be used as the credential in the Basic Auth header.
        </Description>
        <TocLink text="Steps" hash="steps" />
        <OrderedList>
          <Stack sx={{ gap: 2 }}>
            <NumberListItem>
              Obtain the <InlineBlock text="access_token" />:<br /> First,
              retrieve the <InlineBlock text="access_token" /> by making a
              request to the <InlineBlock text="/oauth/token" /> endpoint. For
              example, you might receive a token such as{' '}
              <InlineBlock text="YOUR_ACCESS_TOKEN" />.
            </NumberListItem>
            <NumberListItem>
              Generate the MD5 Digest:
              <br />
              Calculate the MD5 hash of the <InlineBlock text="access_token" />.
              This hash will be used as the credential for HTTP Basic
              Authentication.
            </NumberListItem>
            <CodeBlock code={STEP2_EXAMPLE_CODE} />
            <NumberListItem>
              Access the Endpoint with the MD5 Digest:
              <br />
              To access a protected resource (e.g.,{' '}
              <InlineBlock text="/reports" />
              ), include the MD5 hash in the Authorization header of your HTTP
              request, as the username for Basic Authentication. The password
              field is not required and can be left empty or ignored.
            </NumberListItem>
            <CodeBlock code={STEP3_EXAMPLE_CODE} />
          </Stack>
        </OrderedList>
        <Box id={ScrollableContainerId.BOTTOM_ID} />
      </Stack>
      <TocList tocList={tocList} />
    </Box>
  </>
)
