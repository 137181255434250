import { Box, Stack } from '@mui/material'
import { CodeBlock } from 'components/CodeBlock/CodeBlock'
import { Description } from 'components/Documents/Description'
import { H1Title } from 'components/Documents/H1Title'
import { H2ColorTitle } from 'components/Documents/H2ColorTitle'
import { H2Title } from 'components/Documents/H2Title'
import { TocLink } from 'components/Documents/TocLink'
import { TocList } from 'components/Documents/TocList'
import { InlineBlock } from 'components/InlineBlock/InlineBlock'
import { ListItem, UnorderedList } from 'components/List/List'
import { DocumentContentWidth, ScrollableContainerId } from 'constants/document'
import { getPageTitleAPIDocumentByTitle } from 'constants/pageTitle'
import {
  API_DOC_V2_IP_TITLE,
  API_DOC_V2_TITLE,
} from 'pages/APIDocumentPage/title'
import { Helmet } from 'react-helmet'

const EXAMPLE_REQUEST = `curl "https://api.threatvision.org/api/v2/network/ips/43.255.104.146/adversaries" \\
  -H "Authorization: Bearer YOUR_ACCESS_TOKEN"
`

const EXAMPLE_RESPONSE_BODY = `{
  "success":true,
  "id":"43.255.104.146",
  "analysis_status":true,
  "adversaries":[
    {
      "name":"Amoeba",
      "aliases":[
        "Winnti",
        "APT41",
        "Barium",
        "Wicked Panda",
        "Earth Baku",
        "Double Dragon"
      ],
      "origin_countries":["China"],
      "targeted_countries":[
        "Taiwan",
        "Japan",
        "South Korea",
        "United States of America",
        "Thailand",
        "Singapore",
        "India",
        "Malaysia",
        "Hong Kong",
        "China"
      ],
      "targeted_industries":[
        "Media",
        "Education",
        "Energy",
        "Government",
        "Critical Infrastructure",
        "Telecommunication",
        "Gambling",
        "Semiconductor",
        "Financial Institution",
        "Healthcare",
        "Aerospace",
        "Manufacturing",
        "Gaming",
        "Chemicals",
        "Information Technology"
      ],
      "overview":"Amoeba is a Chinese adversary group widely known as “Winnti,” named after one of its most infamous RATs. Although the Amoeba group is best known for its preference for attacking gaming industry, it has now expanded its scope to other sectors including telecommunication, chemistry, pharmacy, aviation, etc. In 2020, the U.S. government indicted five Amoeba actors, pointing out that the group has strong connections with China's Ministry of State Security (MSS), the top intelligence agency of the Chinese government. ",
      "last_updated_at": 1695916800
    }
  ]
}
`

const tocList = [
  { id: 'api-endpoint', text: 'API Endpoint' },
  { id: 'response-format', text: 'Response Format' },
  { id: 'example-request', text: 'Example Request (curl)' },
  { id: 'example-response-body', text: 'Example Response Body' },
]

const TITLE = 'Adversaries'

export const APIDocumentVersionTwoIPAdversaries = () => (
  <>
    <Helmet>
      <title>
        {getPageTitleAPIDocumentByTitle(
          `${TITLE} - ${API_DOC_V2_IP_TITLE} - ${API_DOC_V2_TITLE}`
        )}
      </title>
    </Helmet>
    <Box sx={{ display: 'flex' }}>
      <Stack
        id={ScrollableContainerId.TOP_ID}
        sx={{
          width: DocumentContentWidth.WIDTH,
          maxWidth: DocumentContentWidth.MAX_WIDTH,
          minWidth: DocumentContentWidth.MIN_WIDTH,
          px: 10,
          py: 4,
          gap: 2,
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        <H1Title title={API_DOC_V2_TITLE} />
        <H2ColorTitle title={API_DOC_V2_IP_TITLE} />
        <H2Title title={TITLE} />
        <Description>
          Lists all attack groups that are related to this IP.
        </Description>
        <TocLink text="API Endpoint" hash="api-endpoint" />
        <Description>
          GET{' '}
          <InlineBlock text="https://api.threatvision.org/api/v2/network/ips/:address/adversaries" />
        </Description>
        <TocLink text="Response Format" hash="response-format" />
        <Description>JSON object, with following keys:</Description>
        <UnorderedList>
          <ListItem>
            success
            <UnorderedList>
              <ListItem>The response status</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            analysis_status
            <UnorderedList>
              <ListItem>The IP had been analyzed</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            id
            <UnorderedList>
              <ListItem>The IP address</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            adversaries
            <UnorderedList>
              <ListItem>
                Array of related adversaries, each object contains following
                keys:
                <UnorderedList>
                  <ListItem>name: string</ListItem>
                  <ListItem>aliases: array of strings</ListItem>
                  <ListItem>origin_countries: array of strings</ListItem>
                  <ListItem>targeted_countries: array of strings</ListItem>
                  <ListItem>targeted_industries: array of strings</ListItem>
                  <ListItem>overview: text</ListItem>
                  <ListItem>last_updated_at: unix timestamp</ListItem>
                </UnorderedList>
              </ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
        <TocLink text="Example Request (curl)" hash="example-request" />
        <CodeBlock code={EXAMPLE_REQUEST} />
        <TocLink text="Example Response Body" hash="example-response-body" />
        <CodeBlock code={EXAMPLE_RESPONSE_BODY} />
        <Box id={ScrollableContainerId.BOTTOM_ID} />
      </Stack>
      <TocList tocList={tocList} />
    </Box>
  </>
)
