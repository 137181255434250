export const API_DOC_AUTH = 'Authentication'
export const API_DOC_V2_TITLE = 'API Version 2'
export const API_DOC_V2_DOMAIN_TITLE = 'Domain'
export const API_DOC_V2_IOC_TITLE = 'IoC Bundles'
export const API_DOC_V2_IP_TITLE = 'IP'
export const API_DOC_V2_PMR_TITLE = 'Patch Management Report (PMR)'
export const API_DOC_V2_SAMPLE_TITLE = 'Sample'
export const API_DOC_V2_INTEL_REPORTS_TITLE = 'Intelligence Reports'
export const API_DOC_V2_ADVERSARY_TITLE = 'Adversary'
export const API_DOC_V2_MALWARE_TITLE = 'Malware'
export const API_DOC_V2_CAPABILITY_TITLE = 'Capability'
export const API_DOC_V2_RAW_INTEL_TITLE = 'Raw Intel'
